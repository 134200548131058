import React, { Fragment, useContext , useEffect, useState, useMemo } from 'react';

import { Doughnut } from "react-chartjs-2";

import styled from 'styled-components'
import clienteAxios from '../../config/axios';


import Modal from '@material-ui/core/Modal'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';  
import PropTypes from 'prop-types';

/// fade del modal
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


////////
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


import { 
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Container,
    FormGroup,
    Input
  } from "reactstrap";  


  import Spinner from '../spinners/Spinner'
  import AuthContext from '../../context/autenticacion/authContext'



  // aqui solo aparece el responsive pequenho
const DivMobile = styled.div `
padding: 0rem !important;
margin: 0.2rem 0rem 2.0rem 0rem !important;
display:flex !important;
flex-direction:row;
justify-content:center;





@media(min-width:992px) {
  display:none !important;
  justify-content:center;
}



`;

// pantalla grande
const Divdesktop = styled.div `

display:none !important;






@media(min-width:992px) {
  display:block !important;
}



`; 





//// responsive
const Div = styled.div `
  padding: 0rem !important;
  margin: 0.2rem 0rem 2.0rem 0rem !important;
  display:flex !important;
  flex-direction:row;
  justify-content:center;
  
  
  
  
  
  @media(min-width:992px) {
    display:none !important;
    justify-content:center;
  }



`;

// pantalla grande
const DivModal = styled.div `

  display:none !important;
  flex-direction:row;
  width:100%;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  
  
  
  
  
  @media(min-width:992px) {
    display:flex !important;
  }



`; 

/// aqui vamos a poner los estilos para el tap 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#00ff9d',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);





/// aqui van los estilos de los tabs
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    alignItems:'center',
    marginRight: theme.spacing(0),
    borderRadius:'30px',
    transition:'all 500ms ease',
    padding:'0px 5px 0px 5px',
     margin:'0px 4px 0px 4px',
     alignItems:'center',
  
    '&:focus': {
      opacity: 0.8,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#005eff',
      borderRadius:'30px'
      
      
    },
    '&:hover': {
      opacity: 0.6,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#000',
      borderRadius:'30px'
    },

  }
  
  ,
}))((props) => <Tab disableRipple {...props} />);
///// aqui terminan los estilos del tap





function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


///// aqui vamos a poner la grafica 




/// chart
let chartPie = {
    data4: (canvas) => {
      let ctx = canvas.getContext("2d");
      
   
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      let invertido=document.getElementById("pinvertido").value;
      let disponible=document.getElementById("pdisponible").value;
  
      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      
      
     
      return {
        type: 'doughnut',
        
        datasets: [
          {
            label: "Data",
            fill: true,
            backgroundColor:['#20c284', '#1720ac' ],
            borderColor: ['#00ff9d', '#1f8ef1' ],
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [parseFloat(invertido),parseFloat(disponible)],
            weight: 300,
          } 
        ] ,
  
        labels: ['Monto Invertido','Monto Disponible'],
  
  
      };
    },
    options: chart_pie_options ,
    enable: pdisponible,
    investment: pinvertido
  
  
  }; 
  
  
   
  
  
  let pdisponible = pdisponible
  let pinvertido = pinvertido
  
  /// ahora va el option del chart
  let chart_pie_options = {
    maintainAspectRatio: true,
    legend: {
      display: false
    },
    labels:{
      color:"#fff",
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#fff",
      bodyFontColor: "#fff",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          DoughnutPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "#fff",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#fff"
          }
        }
      ],
      xAxes: [
        {
          Percentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#fff"
          }
        }
      ]
    }
  };

  
  
  


const ModalPrimarioPro = ({ guardarProjectid,setModalInfo,modalInfo, onSubmit, onChange, amount, open, handleClose, handleOpen, mensaje, cargando, guardarCargando,ApiLoad,apiload}) => { 


  const authContext = useContext(AuthContext);

  const { 
    
    TodosOferentes, 
    TodosOfertas,
    TodosInversiones,
    TodosInversionesVendidas,

    TodosProyectosPrimario,
    TodosProyectosSecundario, 

    Movimientos,
    Saldos,

    demo_status,


   } = authContext;  


   const [ modalpdf , guardarModalPdf] = useState(null);
   const [ modalbro , guardarModalBro] = useState(null);

   const [numerodecontrato, guardarNumeroContrato] = useState(null);

   const [ parcelas, guardarAsociadas] = useState([{
    amount:'', 
    amount_investment:'', 
    amount_reserved:'', 
    approval_date:'', 
    approved_by:'', 
    category:'', 
    category_id:'', 
    code:'', 
    contract_type:'', 
    country_iso:'', 
    created_at:'', 
    created_by:'',
    cuenta_negociador:'',
     currency_code:'', 
     date_max_investment:'', 
     delay_percentage:'', 
     email_sent:'', 
     end_date:'', 
     fee_total_contract:'', 
     file:'', 
     finished_date:'', 
     frequency_payment:'', 
    id:'', 
    issuer:'', 
    loan_type:'', 
    min_amount_start:'',
    name:'' , 
    nrocontrato:'', 
    oferente:'', 
    oferente_id:'', 
    quantity:'', 
    quantity:'', 
    risk:'', 
    start_date:'',
    status:'', 
    tax_price_cuota:'', 
    tax_referential_client:'', 
    tax_referential_panther:'',
    total_inversores:'', 
    updated_at:'', 
    updated_by:'', 
    warranty:'',
    disponible: '',
  }]); 



   useEffect(() => {
     guardarModalPdf(modalInfo.pdf_oferente);
     guardarModalBro(modalInfo.brouchure); 
     guardarNumeroContrato(modalInfo.nrocontrato);
     consultarContratos()
     
     
    }, [modalInfo]) 



    const consultarContratos = async inversion => {

      

      try {
          const respuesta = await clienteAxios.post('/api/client/projects/asociadas', {"nrocontrato":`${modalInfo.nrocontrato}`});
          guardarAsociadas(respuesta.data.data); 
      } catch (error) {
        return
         
      }
  }
  
  

  const guardar = ( cargando ) ? <Spinner/> :  
                                          
                                          <Fragment>
                                            Invertir 
                                            </Fragment> 




    /////////// esto funciona para el suap

    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  
  
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    }; 

    /////////////////////////////////

    const [chartstate, setChartState] = useState("data4");

    const bgVideo = 'https://video.wixstatic.com/video/926174_25a2d39982244a9bb262e467bebf2e58/360p/mp4/file.mp4';

    const [videohold, guardarVideoHold] = useState(false);

    const ActivateVideo = () => {
      
      if ( videohold === false ){
        guardarVideoHold(true);
      }

      if ( videohold ===  true ){
        guardarVideoHold(false);
      }
    }

  

    
    

    // vamos a extraer el context de emergencia para guardar los valores de la inversion 
    // despues 
  
    
    // vamos a hacer que el mensaje salga
    
  
    
     // configuracion del modal de material-ui
     const [ modalStyle ] =useState(getModalStyle);
     
  
     const classes = useStyles();
  
    
     const inversionminima = () => {

          let monto_minimo=50;
      if(modalInfo.disponible < 50) 
       monto_minimo = modalInfo.disponible;
      return monto_minimo; 

          }


          const [mostrarparcelas , guardarMostrarParcelas] = useState(false);


          const abrirParcelas = () => {
            

            if(mostrarparcelas === true ) {
              guardarMostrarParcelas(false)
            } if (mostrarparcelas === false ) {
              guardarMostrarParcelas(true)
            }

          }
  



    return ( 



        <Fragment>

    
              <Modal 
                            open={open}
                            className="modal-responsive-fix-invertir"
                       
                        >   

                        


                            <div  className={`  card  modal-power-up-profile  invertir-fix-mobile m-0`}>

                            <form onSubmit={onSubmit}> 

                                  <p className="mercadoprimario-header"><span>Desde pequeñas inversiones hasta grandes negocios</span> Ofertas Primarias  </p>
                            <div className="header-container-grid-modal">

                                  <div className="elemento-modal-1-header">
                                  <h3 >Detalle de la inversión de <span>{modalInfo.name} {modalInfo.nrocontrato}</span> </h3>
                                  </div> 


                                  <div> 
                                      
                                  <div className="elemento-modal-2-header btn  btn-panther-power btn-primario" onClick={ () => (
                                                            handleClose(),
                                                            guardarModalPdf(null),
                                                            guardarModalBro(null)
                                                            
                                                          )}>
                                                          <div className="cancel-btn-panther" > 
                                                              <i className="tim-icons icon-simple-remove"/>
                                                          </div>
                                                              
                                                      </div>
                                  </div>


                                  </div>
                            
                           

                                  
                                
                                  
                                    

                                     
                                        
                                                           {/* responsive start */}
                                                           
                                  <Div > 
                                  
                                  
                                    <div className="responsive-width-fix">
                                        <AppBar position="static" color="default" className="app-panther-bar">
                                        <StyledTabs value={value} 
                                                  onChange={handleChange} 
                                                  aria-label="styled tabs example"
                                                  className=" pr-0 pl-0 justify-content-center"
                                                  >

                                            <StyledTab  label="Datos de la Oferta" {...a11yProps(0)} />
                                            <StyledTab label="Distribucion " {...a11yProps(1)} />
                                            <StyledTab label="Saldo en la Cuenta" {...a11yProps(2)} />
                                            
                                        </StyledTabs>


                                          
                                        </AppBar>
                                        <SwipeableViews
                                          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                          index={value}
                                          onChangeIndex={handleChangeIndex}
                                         
                                          
                                        >
                                          <TabPanel value={value} index={0} dir={theme.direction} >

                                            
                                          <Row >
                                        
                                            <Col sm="12">
                                            <div className=" m-0 ">
                                                <CardHeader  className="p-0">
                                                    <h5 className="title display-4">Datos de la Oferta </h5>
                                                    <p className="text-success border-success border-bottom pb-2"></p>
                                                </CardHeader>
                                                <CardBody >
                                                    
                                                    <div className="acc-config-panther">

                                                            <Container >
                                                            <Row> 
                                                            <Col xs="12" sm="12" className="align-items-center d-flex justify-content-center">
                                                                <div className="col-11 my-4">
                                                                    <a href={ `${modalInfo.file}`} rel="noopener noreferrer" target="_blank" > 
                                                                    <img src={modalInfo.imagen} /> 
                                                                    </a>
                                                                    
                                                                    </div> 

                                                                    </Col>


                                                                <Col xs="6" sm="6">
                                                                 <ul className="pl-3"> 
                                                                 <li>Tipo de Oferta:   <br/><strong> {modalInfo.tipo}</strong>   </li>
                                                                    <li>Monto:<br/> <strong> {modalInfo.amount_proyecto}   { demo_status === "active" ? 'USDT' : ' $ '  }    </strong> </li>
                                                                    <li>Monto Invertido <br/><strong> {modalInfo.amount_invertido} { demo_status === "active" ? 'USDT' : ' $ '  }    </strong> </li> 
                                                                    <li>Tasa de Interés: <br/><strong> {modalInfo.tax_referential_client} % </strong> </li>
                                                                    <li>Garantía: <br/><strong> {modalInfo.warranty} </strong> </li>


                                                                 </ul>

                                                                </Col>   
                                                                <Col xs="6" sm="6"> 
                                                              <ul className="pl-3">
                                                              <li>Clasificación: <br/> <strong> {modalInfo.risk} </strong> </li>
                                                                    <li>Fecha de Rendimiento: <br/><strong> {modalInfo.end_date} </strong> </li>
                                                                    <li>Tiempo Total de Contrato <br/><strong> {modalInfo.dias} d. </strong> </li>
                                                                    <li>Tiempo Restante de Contrato <strong> {modalInfo.tiemporemanente} d. </strong> </li>
                                                                    
                                                              </ul>
                                                              

                                                              </Col>



                                            

                                                            </Row>
                                                            </Container>
                                                            <div className={` imagen-mis-inversiones-fix-dos`}>
                                                    <div class="row">
                                                      <div class="col-12">
                                                      <p className="title display-5 text-left my-1">Nombre de la oferta : <br/> {modalInfo.name}</p>

                                                      </div> 

                                                      <p className="w-100 container descripcion-fix">
                                                        <strong> Descripción: </strong> <br/>
                                                      {modalInfo.description}

                                                      </p>

                                                      <div className={ modalpdf === "" || modalpdf === null || modalpdf === "null" || modalpdf === 'null' ? 'd-none col-6' : 'd-block col-6'}> 


                                                          <a href={ `${modalInfo.pdf_oferente}`} rel="noopener noreferrer" target="_blank" > 
                                                          <button
                                                              color="link"
                                                              title=""
                                                              type="button"
                                                              className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power-sm mt-1"
                                                          
                                                              
                                                            > 
                                                              
                                                              Datos de oferta
                                                            </button>
                                                          
                                                                          
                                                                            </a>
                                                                            
                                                          </div>
                                                          <div className={ modalbro === " " || modalbro === null || modalbro === "null" || modalbro === 'null' ? 'd-none col-6 mb-3' : 'd-block col-6 mb-3'}>
                                                            
                                                                    
                                                                  
                                                            

                                                          <a href={ `${modalInfo.brouchure}`} rel="noopener noreferrer" target="_blank" > 
                                                          <button
                                                              color="link"
                                                              title=""
                                                              type="button"
                                                              className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power-sm mt-1"
                                                          
                                                              
                                                            > 
                                                              
                                                              Catálogo
                                                            </button>
                                                            </a>
                                                          </div>


                                                    </div>
                                             
                                                 </div>
                                                
                                                
                                                
                                                    </div>
                                                </CardBody> 


                                                <div className="invertir-btn-responsive">

                                                <h3 className="text-left m-0">Estás a punto de invertir</h3> 
                                                        <label >Click para continuar al paso final</label>
                                                    
                                                      <div  className="btn btn-radius mt-2 btn-invertir  btn-block" onClick={ ()=>(
                                                        setValue(2)
                                                      )
                                                      }>
                                                      Invertir
                                                      </div>
                                                </div>

                                                {/* <CardFooter className="d-flex justify-content-end flex-direction-collumn-panther">
                                                <h3 className="text-left m-0">Monto a invertir</h3> 
                                                              <label htmlFor="amount" >Digite el monto a invertir</label>
                                                                <input
                                                                    type="text"
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder="USDT"
                                                                    onChange={onChange}
                                                                    
                                                                    value={amount}
                                                                    className="btn-block  display-4  text-center input-invertir"
                                                                    />
                                                            <Button type="submit" className=" btn-invertir  btn-block" color="default" data-toggle="modal" data-target="#acc-config" data-whatever="Editar">
                                                            {guardar}
                                                            </Button>
                                                        </CardFooter> */}
                                            
                                            
                                                </div>
                                            </Col>

                                          </Row>
                                          </TabPanel>

                                          <TabPanel value={value} index={1} dir={theme.direction}>

                                            <Row >
                                                <Col sm="12"> 
                                                      <div className=" m-0">
                                                      <CardHeader  className="p-0">
                                                      <h5 className="title display-4">Distribución del contrato</h5>
                                                          <p className="text-success border-success border-bottom pb-2"></p>
                                                      </CardHeader>
                                                      <CardBody> 
                                                              <Col md="12">

                                                                      <div className="kyc-containDer">
                                                                      <CardBody className="p-0">
                                                                      <div className="chart-area">
                                                                        <Doughnut
                                                                          data={chartPie[chartstate]}
                                                                          options={chartPie.options}
                                                                          modalInfo={modalInfo}
                                                                          className="doughnut-style"
                                                                          
                                                                        />
                                                                      </div>
                                                                    </CardBody>
                                                                          
                                                                      </div>

                                                                  </Col> 

                                                                  
                                                    
                                                      </CardBody> 
                                                      <CardFooter className="d-flex justify-content-center distribucion-contrato-footer"> 
                                                      <div className="row d-flex acc-config-panther justify-content-center width-100">
                                                        <div className="col-6">
                                                        <ul>      
                                                                  <li className="text-center">{modalInfo.amount_invertido} { demo_status === "active" ? 'USDT' : ' $ '  }   </li> 
                                                                  <li className="text-center distribucion-contrato-footer-title" > {modalInfo.percentage_investment}  %</li> 
                                                                  <li className="text-center">Monto Invertido </li> 

                                                          </ul>

                                                        </div> 
                                                        <div className="col-6">
                                                        <ul>       
                                                                  <li className="text-center">{modalInfo.disponible} { demo_status === "active" ? 'USDT' : ' $ '  }  </li> 
                                                                  <li className="text-center distribucion-contrato-footer-title"> {modalInfo.percentage_enable} %</li> 
                                                                  <li className="text-center">Monto Disponible </li> 

                                                          </ul>

                                                        </div>
                                                          </div>
                                                      </CardFooter>
                                                 <CardFooter className="d-flex justify-content-end flex-direction-collumn-panther">

                                                      <h3 className="text-left m-0">Estás a punto de invertir</h3> 
                                                              <label  >Click para continuar al paso final</label>
                                                          
                                                            <div  className="btn btn-radius mt-2 btn-invertir  btn-block" onClick={ ()=>(
                                                              setValue(2)
                                                            )
                                                            }>
                                                             Invertir
                                                            </div>
                                                        </CardFooter>  
                                                  
                                                </div>
                                                    
                                                
                                                </Col>
                                                </Row> 

                                          </TabPanel> 
                                          <TabPanel value={value} index={2} dir={theme.direction}>

                                            <Row >
                                                <Col sm="12"> 
                                                

                                                        <div className=" invertir-fx m-0"> 
                                                        
                                                        <CardHeader className="p-0">
                                                            <h5 className="title display-4">Saldo en la cuenta</h5>
                                                            <p className="text-success border-success border-bottom pb-2"></p>
                                                            
                                                        </CardHeader>
                                                        <CardBody>
                                                            
                                                            <div className="acc-config-panther">
                                                                

                                                            <ul>    
                                                            <h2 >Saldo disponible en wallet</h2> 
                                                              <p className="text-success border-success border-bottom text-right font-disponible">{modalInfo.disponiblewallet}</p>
                                                              <h3 className="text-left m-0">Monto a invertir</h3> 
                                                              <label htmlFor="amount" >Digite el monto a invertir</label>
                                                                <input
                                                                    type="number"
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder={ demo_status === "active" ? 'USDT' : ' $$$ '  }
                                                                    onChange={onChange}
                                                                    min={inversionminima}
                                                                    step="0.01"
                                                                    value={amount}
                                                                    className="btn-block  display-4  text-center input-invertir"
                                                                    />

                                                                    <li className="display-4"> Ganancia:    </li>
                                                                    <li className="text-right m-0 p-1">{modalInfo.ganancia}  % </li>
                                                                    <li className="display-4">Valor a recibir:    </li>
                                                                  <li className="text-right m-0 p-1" ><p id="totalarecibir">0</p></li>
                                                                    
                                                            </ul>

                                                            </div>
                                                        </CardBody> 
                                                        <CardFooter className="d-flex justify-content-end">
                                                            <Button type="submit" className=" btn-invertir  btn-block" color="default" data-toggle="modal" data-target="#acc-config" data-whatever="Editar">
                                                            {guardar}
                                                            </Button>
                                                        </CardFooter>

                                                        </div> 

                                                    
                                                    
                                                  </Col>
                                                </Row> 

                                          </TabPanel>
                                    
                                        </SwipeableViews>
                                      </div>      

                                      
                                  </Div>

                                  {/* responsive end */}

                                  <DivModal >

                                    <>


                                    <div className={`grid-container-modal-pro ${mostrarparcelas === true ? 'blur-master':''} `}>

                                    


                                        <div className={`elemento-pro-1 ${mostrarparcelas === false  ? 'animation-fx-modal-1' : ''}`}> 
                                                            <h2>Datos de la oferta</h2>
                                                            <p >Nombre de la oferta : {modalInfo.name}</p>

                                                <div className="grid-pro-datos"> 

                                                            <p>Tipo de Oferta:   <strong> {modalInfo.tipo}</strong>   </p>
                                                            <p>Monto: <strong> {modalInfo.amount_proyecto} { demo_status === "active" ? 'USDT' : ' $ '  }   </strong> </p>
                                                            <p>Monto Invertido <strong> {modalInfo.amount_invertido} { demo_status === "active" ? 'USDT' : ' $ '  }   </strong> </p> 
                                                            <p>Tasa de Interés: <strong> {modalInfo.tax_referential_client} % </strong> </p>
                                                            <p>Garantía: <strong> {modalInfo.warranty} </strong> </p>
                                                            <p>Clasificación: <strong> {modalInfo.risk} </strong> </p>
                                                            <p>Fecha de Rendimiento: <strong> {modalInfo.end_date} </strong> </p>
                                                            <p>Tiempo Total de Contrato <strong> {modalInfo.dias} d. </strong> </p>
                                                            <p>Tiempo Restante de Contrato: <strong> {modalInfo.tiemporemanente} d. </strong> </p>
                                                </div>  

                                                

                                                    <h2>Descripción</h2>
                                                    <p className="descripcion-fix">{modalInfo.description}</p> 

                                                    
   
                                                <div class="grid-pro-btn">
                                                     
                                                      

                                                      <div className={ modalpdf === "" || modalpdf === null || modalpdf === "null" || modalpdf === 'null' ? 'd-none ' : 'd-grid '}> 

                                                            


                                                          <a href={ `${modalInfo.pdf_oferente}`} rel="noopener noreferrer" target="_blank" > 
                                                          <button
                                                              color="link"
                                                              title=""
                                                              type="button"
                                                              className="btn btn-primario btn-block "
                                                          
                                                              
                                                            > 
                                                              
                                                              Datos de oferta
                                                            </button>
                                                          
                                                                          
                                                                            </a>
                                                                            
                                                          </div>
                                                          <div className={ modalbro === " " || modalbro === null || modalbro === "null" || modalbro === 'null' ? 'd-none ' : 'd-grid '}>
                                                                    
                                                                  
                                                            

                                                          <a href={ `${modalInfo.brouchure}`} rel="noopener noreferrer" target="_blank" > 
                                                          <button
                                                              color="link"
                                                              title=""
                                                              type="button"
                                                              className="btn btn-primario btn-block "
                                                          
                                                              
                                                            > 
                                                              
                                                              Catálogo
                                                            </button>
                                                            </a>
                                                          </div>


                                                    </div>
                                             

                                        </div> 







                                        <div className={`elemento-pro-2 ${mostrarparcelas === false  ? 'animation-fx-modal-2' : ''}`}>

                                          <h2>Distribución del contrato</h2>  
                                          <p > {modalInfo.name}</p>
                                          <h5>{modalInfo.nrocontrato}</h5>
                                          
                                          <div className="elemento-pro-2-imagen d-none">
                                            <img src={modalInfo.imagen}/>

                                          </div>
                                          
                                          <div className="chart-grid-area">
                                                                  <Doughnut
                                                                    data={chartPie[chartstate]}
                                                                    options={{
                                                                      legend: {
                                                                        display: false,
                                                          
                                                                        labels: {
                                                                          
                                                                          defaultFontSize: '24px'
                                                                        }
                                                          
                                                                       
                                                                      },
                                                                    }}
                                                                    
                                                                    
                                                                    enable={chartPie.enable}
                                                                    investment={chartPie.investment}
                                                                    className="doughnut-style"
                                                                  />
                                            </div> 

                                                {modalInfo.video !== "" ? 
                                                <div className="video-show-control">
                                                <p onClick={ActivateVideo}> 
                                                <i class="tim-icons icon-triangle-right-17" ></i>
                                                </p>
   
                                               </div> :null 
                                              }

                                            

                                            <>  
                                              <div className="box-parcelas">
                                                    <div className="parcelas-aso">

                                                      <div className="parcela-info">
                                                      {parcelas.length <= 1 ?
                                                            <h3> Unica Parcela Asociada</h3> : 
                                                            <h3> {parcelas.length} Parcelas Asociadas</h3> 
                                                          }

                                                      <h4>
                                                        Parcela Actual 
                                                        <span>
                                                        {modalInfo.nrocontrato}
                                                        </span>
                                                      </h4>

                                                      </div>
                                                          
                                                      
                                                      {mostrarparcelas === true ? <p onClick={abrirParcelas}> 
                                                      <VisibilityIcon  /> ocultar lista </p>: 
                                                      <p onClick={abrirParcelas}> 
                                                      Abrir lista de parcelas </p> }

                                                    </div>
                                                </div>


                                                  </>


                                            <div className="chart-grid-area-label"> 

                                                    <div className="element-label-area border-green-bottom">
                                                          
                                                          
                                                          <p >{modalInfo.amount_invertido}
                                                           
                                                           <span> { demo_status === "active" ? 'USDT' : ' $ '  }   </span>
                                                           </p>  
                                                                    <strong > {modalInfo.percentage_investment}  %</strong> 
                                                                  <p >Monto Invertido </p> 

                                                            

                                                          </div>  

                                                          <div className="element-label-area border-blank-bottom">  
                                                              <i className="tim-icons icon-single-02"></i> 
                                                              <small>Participantes </small>
                                                              <strong> {modalInfo.total_inversores} </strong>
                                                          </div>

                                                          <div className="element-label-area border-blue-bottom">
                                                                   <p >{modalInfo.disponible} <span> { demo_status === "active" ? 'USDT' : ' $ '  }  </span> </p> 
                                                                        <strong > {modalInfo.percentage_enable} %</strong> 
                                                                        <p >Monto Disponible </p> 
                                                          </div>

                                            </div>

                                            

                                            



                                            <div className={`video-show ${videohold === true ? 'show-video-class':''}`}>

                                            <i class="tim-icons icon-simple-remove " onClick={ActivateVideo}></i>

                                              
                                            <video src={modalInfo.video} autoplay="true" loop muted>
                                            </video>


                                            </div>



                                                    <input type="hidden" id="pinvertido" value={modalInfo.percentage_investment}></input>
                                                    <input type="hidden" id="pdisponible" value={modalInfo.percentage_enable}></input>


                                        </div>
                                        
                                      <div className={`elemento-pro-3 ${mostrarparcelas === false  ? 'animation-fx-modal-3' : ''}`}> 


                                        

                                        <h2 >Saldo disponible en wallet</h2> 
                                        <p className="saldo-dis" >{modalInfo.disponiblewallet} <span> { demo_status === "active" ? 'USDT' : ' $ '  } </span> </p>
                                                              

                                                          
                                                             
                                                              <h3 >Monto a invertir</h3> 
                                                              <label htmlFor="amount" >Digite el monto a invertir</label>
                                                           


                                                                <input
                                                                    type="number"
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder={ demo_status === "active" ? 'USDT' : ' $$$ '  }   
                                                                    onChange={onChange}
                                                                    min={inversionminima}
                                                                    step="0.01"
                                                                    value={amount}
                                                                    className="btn-block  display-4  text-center input-invertir"
                                                                    /> 


                                                                    <div className="calculo-ele-3"> 

                                                                        <p > Ganancia:    </p>
                                                                      <strong  >{modalInfo.ganancia}  % </strong>
                                                                      <p >Valor a recibir:    </p>
                                                                      <strong  ><span id="totalarecibir">0</span></strong>

                                                                    </div>

                                
                                                                  
                                                                  
                                                          
                                                      
                                                          

                                                          <div className="btn-invertir-container-grid ">
                                                          <Button type="submit" className=" btn-invertir  btn-block" color="default" data-toggle="modal" data-target="#acc-config" data-whatever="Editar">
                                                          <p> 
                                                          {guardar}

                                                          </p>
                                                          
                                                          </Button>
                                                      </div> 
                                                     

                                        </div>



                                    </div>

                                    <div className={`  grid-list-modal "  ${mostrarparcelas === true ? 'd-block z-index-hd':'height-0'}`}> 

                                            {mostrarparcelas === true ? 
                                            <p onClick={abrirParcelas}> <VisibilityIcon className="icon-vis-style" /> Cerrar X parcelas asociadas </p>
                                            : null
                                            // <p onClick={abrirParcelas}> <VisibilityOffIcon className="icon-vis-style"/> Mostrar parcelas asociadas</p> 
                                            }


                                                    {mostrarparcelas == true ? 
                                                    <h3> Parcelas Asociadas </h3> :null
                                                    
                                                    }

                                                    
                                            


                                            <div  className={`  header-grid-asociadas "  ${mostrarparcelas === true ? 'd-grid z-index-hd':'d-none '}`} >
                                                                                      <div >           
                                                                                              <strong>
                                                                                              
                                                                                              País <br/>
                                                                                              
                                                                                                </strong>
                                                                                              </div>  

                                                                                              <div >
                                                                                              <strong >
                                                                                              
                                                                                              Descripcion de <br/> la inversión  
                                                                                              
                                                                                                </strong>
                                                                                              </div>  
                                                                                      <div >
                                                                                              <strong>
                                                                                              
                                                                                              Tipo <br/>
                                                                                              
                                                                                                </strong>
                                                                                              </div>  
                                                                                              <div >           
                                                                                              <strong>
                                                                                              
                                                                                              Categoria de <br/> la inversión 
                                                                                              
                                                                                                </strong>
                                                                                              </div>  

                                                                                              <div >
                                                                                              <strong >
                                                                                              
                                                                                              Número   <br/>  contrato
                                                                                              
                                                                                                </strong>
                                                                                              </div>  
                                                                                              <div >           
                                                                                              <strong>
                                                                                              
                                                                                              Clasificación <br/>
                                                                                              
                                                                                                </strong>
                                                                                              </div>                                                                    
                                                                                      <div >
                                                                                              <strong>
                                                                                              
                                                                                              Garantia <br/>
                                                                                              
                                                                                                </strong>
                                                                                              </div> 
                                                                                              <div >           
                                                                                              <strong>
                                                                                              
                                                                                              Interes  <br/>
                                                                                              
                                                                                                </strong>
                                                                                              </div> 

                                                                                              <div >           
                                                                                              <strong>
                                                                                              
                                                                                              Vencimiento <br/>
                                                                                              
                                                                                                </strong>
                                                                                              </div> 
                                                                                      <div >
                                                                                              <strong>
                                                                                              
                                                                                              Monto  <br/>
                                                                                              
                                                                                                </strong>
                                                                                              </div> 
                                                                                              <div >
                                                                                              <strong>
                                                                                              Ganancia  periodo <br/>
                                                                                              
                                                                                                </strong>
                                                                                              </div> 

                                                                                      <div >
                                                                                      <strong>
                                                                                      Disponibilidad <br/>
                                                                                      
                                                                                        </strong>
                                                                                      </div> 
                                                                                      <div >
                                                                                      <strong>
                                                                                      Participantes <br/> 
                                                                                      
                                                                                        </strong>
                                                                                        
                                                                                      </div>
                                            </div> 
                                            <div className={`list-box-overflow ${mostrarparcelas === true ? 'list-box-overflow-open' :''} `}>
                                              
                                              {parcelas.map(contrato => (
                                            
                                            <>
 
                                      <div key={contrato.id} className={`  header-grid-asociadas-body animation-cuentas "  ${mostrarparcelas === true ? 'd-grid':'d-none '} ${contrato.nrocontrato === modalInfo.nrocontrato ? 'active-parcelas':''}`} 
                                          onClick={ () => {
                                                
                                                guardarProjectid(contrato.id) 
                                                setModalInfo(contrato);
                                                guardarMostrarParcelas(false);
                                                
                                              }}
                                        > 
                                        <div>
                                                                                    <strong>
                                                                                        <small> {contrato.country_iso}</small> 
                                                                                          </strong>
                                                                                    </div>  
                                                                                    <div >
                                                                                        <strong >    
                                                                                        <small> {contrato.name}</small> 
                                                                                          </strong>
                                                                                        </div>  
                                                                              

                                                                                <div >
                                                                                        <strong>
                                                                                        
                                                                                        
                                                                                        <small> {contrato.tipo}</small> 
                                                                                          </strong>
                                                                                        </div>  



                                                                                <div >
                                                                                        <strong>
                                                                                        
                                                                                        
                                                                                        <small> {contrato.category}</small> 
                                                                                          </strong>
                                                                                        </div>   


                                                                                        <div >
                                                                                        <strong>
                                                                                        
                                                                                        
                                                                                        <small> {contrato.nrocontrato}</small> 
                                                                                          </strong>
                                                                                        </div>  


                                                                                <div >
                                                                                        <strong>
                                                                                        
                                                                                        
                                                                                        <small> {contrato.risk}</small> 
                                                                                          </strong>
                                                                                        </div>  

                                                                                


                                                                                  
                                                                                <div >
                                                                                        <strong>
                                                                                        
                                                                                        
                                                                                        <small> {contrato.warranty}</small> 
                                                                                          </strong>
                                                                                        </div> 



                                                                                


                                                                                      <div >
                                                                                        <strong>
                                                                                        
                                                                                        
                                                                                        <small> {contrato.tax_referential_client} ( % )</small> 
                                                                                          </strong>
                                                                                        </div> 

                                                                                <div >
                                                                                        <strong>
                                                                                        
                                                                                        
                                                                                        <small> {contrato.end_date}</small> 
                                                                                          </strong>
                                                                                        </div> 
                                                                                <div >
                                                                                        <strong>
                                                                                        
                                                                                        
                                                                                        <small> {contrato.amount_proyecto} ( $ )</small> 
                                                                                          </strong>
                                                                                        </div> 


                                                                                

                                                                                        <div >
                                                                                        <strong>
                                                                                        
                                                                                        <small> {contrato.ganancia} ( % )</small> 
                                                                                          </strong>
                                                                                        </div> 

                                                                                <div >
                                                                                <strong>
                                                                                
                                                                                <small> {contrato.disponible} ( $ )</small> 
                                                                                  </strong>
                                                                                </div> 
                                                                                <div >
                                                                                <strong>
                                                                                
                                                                                <small>{contrato.total_inversores}</small>
                                                                                  </strong>
                                                                                  
                                                                                </div>
                                      </div> 
                                      
                                      
                                      </>

                                          ) )}  
                                          </div>

                                      </div>

                                   

                                  
                                    </>
                                           
                                            
                                            

                                            




                                    </DivModal>
                                                      

                                    {/* {mensaje ? ( <span className="text-center mensaje-inversion"> <br/> {mensaje}</span>) : null} */}
                                    { mensaje ? <Modal
                                                
                                                className={classes.modal}
                                                open={open} 
                                                mensaje={mensaje}
                                                TodosProyectosPrimario={TodosProyectosPrimario}
                                                onClose={handleClose}
                                                guardarAsociadas={guardarAsociadas}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{
                                                  timeout: 900,
                                                }}
                                              >
                                                <Fade in={open}
                                                timeout={154}
                                                onClose={handleClose}
                                                TodosProyectosPrimario={TodosProyectosPrimario}
                                                
                                                >
                                                  
                                                  <div className="container">
                                                <div className="row">
                                                  <div className="col-12 d-flex justify-content-center">
                                                  <div  className={`  card ${classes.paper} Modal-inversion modal-power-up modal-power-up-profile`}>
                                                  
                                                  <CardBody>
                                                                      <Row className="d-flex justify-content-center">
                                                                              <Col sm="12" md="6"> 
                                                                                  <p className="text-center display-4 title font-light-weight-modal"> {mensaje}</p>
                                                                              </Col>       
                                                                      </Row>
                                                                    </CardBody> 
                                                                    <CardFooter>
                                                      <div className="row">
                                                          
                                                      <div  className="col-4">
                                                          </div>
                                                          <div  className="col-4">
                                                            <button className=" btn mt-0 btn-block btn-info"      onClick={ () => {
                                                                                        handleClose()   
                                                                                        guardarCargando(false)
                                                                                        TodosOferentes()
                                                                                        TodosOfertas()
                                                                                        TodosInversiones()
                                                                                        TodosInversionesVendidas()
                                                                                        TodosProyectosPrimario()
                                                                                        TodosProyectosSecundario()
                                                                                        Movimientos()
                                                                                        Saldos()
                                                                                        ApiLoad()
                                                                                        guardarModalPdf(null)
                                                                                        guardarModalBro(null)
                                                                                        
                                                                                          }
                                                                                          }  >
                                                            Aceptar
                                                          </button>
                                                          </div>
                                                                
                                                      <div  className="col-4">
                                                          </div>
                                                      </div>
                                                  </CardFooter>
                                                                    
                                                                
                                                  

                                                </div>

                                                  </div>

                                                </div>
                                                  </div>
                                                </Fade>
                                              </Modal> : null} 

                                              


                                   
                                  
                            
                                  </form>      
                            </div>
                        </Modal>
                            
        </Fragment>

     );
}
 
export default ModalPrimarioPro;