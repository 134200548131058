import React, { createContext, useState, useEffect } from 'react'
import clienteAxios from '../../config/axios';


export const FiltroProyectoContext = createContext();


const FiltroProyectoProvider = (props) => {

    const [ busqueda, guardarBusqueda ] = useState({
        pais:'',
        tipo:'',
        emisor:'',
        riesgo:'',
        vencimiento:'',
        rango:'',
    });


    
    const [ pais, guardarPais ] = useState([]);

    const [ oferta, guardarOferta ] = useState([]);
/// aqui estamos colocando los valores, para buscar en la api
    const [ emisor, guardarEmisor ] = useState([]); 


        
    useEffect(() => {

        const consultarApiAct = async () => { 


          try {
            const url = `/api/projects/filtros`
  
            
            const resultado = await clienteAxios.post(url); 
            guardarPais(resultado.data.data.paises);
            guardarOferta(resultado.data.data.tipo_oferta);
            guardarEmisor(resultado.data.data.emisor);
            
            
            

        } catch (error) {
                    return

        } 
         
        }
  
        consultarApiAct();
  
    },[]);





    return (
            <FiltroProyectoContext.Provider
                value={{
                    guardarBusqueda,
                    guardarEmisor,
                    guardarOferta,
                    guardarPais,
                    pais,
                    oferta,
                    emisor,
                    busqueda,

                }}
            
            >
                    {props.children}
            </FiltroProyectoContext.Provider>

    );



}

export default FiltroProyectoProvider