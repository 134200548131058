import React, { useContext, useState, useEffect, Fragment } from 'react';
import clienteAxios from '../../config/axios';
import NumberFormat from 'react-number-format';
import Mensaje from 'components/Alertas/Mensaje';
import LineSpinner from 'components/spinners/LineSpinner'

const DepositosPanama = ({jimf_theme}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []) 


                    const [ spin, setSpin ] = useState(false);

                    const [mensaje, guardarMensaje] = useState('')

                    // const { message } = mensaje;  

                    const [mensajeopen, guardarMensajeOpen] = useState(false);


                    const [deposit, guardarDeposit] = useState({
                    
                        amount:'',
                        type:'2',
                        file:'',
                        note:'',
                        bank:'',
                        fecha:'',
                    
                    });  

                    const { amount,file,note,bank,fecha,type } = deposit;  

                    

                


                const [pdfdatos, guardarPdfDatos] = useState({
                    file_name:'https://panther7.s3.us-east-2.amazonaws.com/banking_transfer.pdf',
                    file_name_eng:'https://panther7.s3.us-east-2.amazonaws.com/banking_transfer.pdf',
                    file_name_pa:'https://panther7.s3.us-east-2.amazonaws.com/banking_transfer.pdf',
                });  



                const consultarApiDeposit = async () => {

                    const { amount,file,note,bank,fecha,type } = deposit;  
                    
                
                      const config = {     
                        headers: { 'content-type': 'multipart/form-data','Accept': 'application/json' },
                        
                        mimeType: 'multipart/form-data',
                    } 
                
                    const formData = new FormData();
                    formData.append('file',document.getElementById('file').files[0],file);
                    formData.append('amount',document.getElementById('amount').value);
                     formData.append('type','2');

                    try {
                            const respuesta = await clienteAxios.post('/api/client/deposits/create',formData,config);
                                
                                guardarMensajeOpen(true)
                               guardarMensaje(respuesta.data.message);
                               setSpin(false)
                            //   setRecargarData(true);
                            //   guardarCargando(false);
                            //   guardarEnviarDepositoBtn(false);
                                // handleOpen(); 
                    } catch (error) {
                        guardarMensajeOpen(true)
                        guardarMensaje(error.response.data.message);
                        setSpin(false)
                                // handleOpen();
                              
                    }
                
                  
                  
                  
                } 
                


                const onChange = e => {  
                    guardarDeposit({
                        ...deposit,
                        [e.target.name] : e.target.value
                        
                    }) 
                } 

                const onSubmit = e => {
                    e.preventDefault();
                    setSpin(true)
                      consultarApiDeposit(deposit);
                } 


                const Loader = ( spin ) ? <LineSpinner/> :  
                                          
      <Fragment>
        Solicitar
        </Fragment> 
  
  


    return ( 

        <>
        
        <div className="window-jimf-retiro box-dual">

                <div className="cuento-deposito">
                        <div className="box-cuento-deposito g-jimf-100">
                        <h1>Transferencia Nacional</h1>
                        <p>Con la siguiente información usted tendra
                            que hacer un depósito y reportarlo en el siguiente campo
                            donde se coloca el <small>Monto</small> y <small>Comprobante bancario. </small>
                            
                        </p>

                        </div>
                        <div className="box-cuento-deposito cool-left">

                        <h5><strong className="">Banco:</strong> Banco BANISTMO </h5>
                        <h5><strong className="">Beneficiario:</strong> COODEM - Cooperativa del Emprendedor</h5>
                        
                        <h5><strong className="">Número de Cuenta:</strong> 0116676868</h5>
                        <h5><strong className="">Tipo de Cuenta:</strong> Corriente </h5>
                        

                        </div>
                        
                </div>

                <div className="form-deposito">

                    <form form onSubmit={onSubmit}>

                        <div className="input-simples-jimf">

                        <label >
                            Monto
                            </label>
                               <div className="box-class">
                               <input 
                                    type="number"
                                    
                                    placeholder="Digite el monto"
                                    id="amount"
                                    name="amount"
                                    max="3000"
                                       onChange={onChange}
                                       value={amount} 
                                    required
                                    /> 
                               </div>


                                    { amount !== '' ?
                                        <span>Usted está reportando un depósito de <NumberFormat value={amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2} className="color-bust-amount" /></span>
                                        :
                                        <span>
                                                Escriba el monto de su depósito en el campo superior 
                                        </span>
                                    }


                               
                               

                        </div>
                        
                        <div className="input-simples-jimf">

<label >
    Referencia
    </label>
       <div className="box-class-text">
       <input 
            type="text"
            
            placeholder="Referencia"
            id="reference"
            name="reference"
            required
            /> 
       </div>


      

       
     

</div>

                            <div className="input-simples-jimf-file">    
                                        { file !== '' ?
                                        <label >
                                        Comprobante Cargado
                                            </label>:<label >
                                        Cargar el comprobante
                                            </label>

                                        }
                                            <div className="box-class-file">
                                            <input 
                                                    type="file"
                                                    id="file"
                                                    name="file"
                                                       onChange={onChange}
                                                       value={file} 
                                                    required
                                                    /> 
                                            </div>
                                            
                                            {/* <span>
                                                Haga click para cargar el comprobante bancario. 
                                            </span> */}

                                        </div>

                                        <button  type="submit" className={`submit-jimf ${spin === true ? 'padd-mad' :''}`}>
                                        {Loader}
                                            </button>

                    


                    </form>

                </div>


                </div>

                <div className="window-select-tipe">
                <h1>Registrar Transferencia Nacional</h1>
                </div>


                {mensajeopen ? <> 
                    <Mensaje
                            open={true}
                            mensaje={mensaje} 
                            guardarMensajeOpen={guardarMensajeOpen}
                            guardarMensaje={guardarMensaje}
                        /> 
                    
                
                </> : null
                
                }

        </>


     );
}
 
export default DepositosPanama;