import React, { useContext, useState, useEffect, Fragment } from 'react';
import clienteAxios from '../../config/axios';
import NumberFormat from 'react-number-format';
import Mensaje from 'components/Alertas/Mensaje';
import LineSpinner from 'components/spinners/LineSpinner';
import AuthContext from '../../context/autenticacion/authContext.js';




const PasswordReset = ({jimf_theme}) => {

    const authContext = useContext(AuthContext);
    const {  cambiarPassword, listDatos } = authContext;


    useEffect(() => {
        window.scrollTo(0, 0)
      }, []) 


                    const [ spin, setSpin ] = useState(false);

                    const [mensaje, guardarMensaje] = useState('')

                    // const { message } = mensaje;  

                    const [mensajeopen, guardarMensajeOpen] = useState(false);

                    const [usuario, guardarUsuario] = useState({
                        current_password:'',
                        new_password:'',
                        confirm_password: '',
                        
                    });


                    const {current_password,new_password,confirm_password} = usuario;

                    

                


            


                const consultarApiReset = async () => {

                      
                    
                

                    try {
                            const respuesta = await clienteAxios.post('/api/client/changepassword',usuario);
                                
                                guardarMensajeOpen(true)
                               guardarMensaje(respuesta.data.message)                               
                               setSpin(false)
                               listDatos()
                               
                    } catch (error) {
                        guardarMensajeOpen(true)
                        guardarMensaje(error.response.data.message)
                        setSpin(false)
                        listDatos()
                                
                              
                    }
                
                  
                  
                  
                } 
                


                const onChange = e => {  
                   
        guardarUsuario({
            ...usuario,
            [e.target.name] : e.target.value

        })
                } 

                const onSubmit = e => {
                    e.preventDefault();
                    setSpin(true)
                      consultarApiReset(usuario);
                } 


                const Loader = ( spin ) ? <LineSpinner/> :  
                                          
      <Fragment>
        Cambiar contraseña
        </Fragment> 



    return ( 

        <>

        <div className="window-jimf-retiro box-dual">

            <div 
            
            className={` ${jimf_theme === "white" ? 'cuento-deposito-white':'cuento-deposito' }`}
            >
                    <div className="box-cuento-deposito g-jimf-100">

                    <h1>Privacidad y Seguridad</h1>
                    <p>Para nosotros es muy importante, su <small>privacidad y seguridad </small>
                    
           

                    
                        
                    </p>

                    </div>
                    <div 
                    
                    className={` ${jimf_theme === "white" ? 'box-cuento-deposito-white cool-left-white':'box-cuento-deposito cool-left' }`}
                    
                    >
                        <h1>Privacidad y Seguridad</h1>
                        <p> 
                         
                        Recomendamos actualizar su contraseña 1 vez <small> cada 30 dias </small> 
                        </p>

                    

                    </div>
                    
            </div>

            <div 
                    className={`${jimf_theme === "white" ? 'form-deposito-white':'form-deposito' }`}
            >

            <form form onSubmit={onSubmit}>

                            <div 
                            
                            className={`${jimf_theme === "white" ? 'input-simples-jimf-password-white':'input-simples-jimf-password' }`}
                            
                            >

                            <label htmlFor="current_password" >
                            Contraseña actual
                                </label>
                                <div >
                                <input 
                                            type="password"
                                            id="current_password"
                                            name="current_password"
                                            placeholder="Su antigua contraseña"
                                            onChange={onChange}
                                            value={current_password}
                                            autoComplete="off"
                                            required
                                        
                                        /> 
                                </div>

                            </div>


                            <div 
                            
                            className={`${jimf_theme === "white" ? 'input-simples-jimf-password-white':'input-simples-jimf-password' }`}
                            
                            >

                            <label htmlFor="password" >
                            Nueva contraseña
                                </label>
                                <div >
                                <input 
                                             type="password"
                                             id="new_password"
                                             name="new_password"
                                             placeholder="Su nueva contraseña"
                                             onChange={onChange}
                                             value={new_password}
                                             autoComplete="off"
                                             required
                                        
                                        /> 
                                </div>

                            </div>

                            <div 
                            
                            className={`${jimf_theme === "white" ? 'input-simples-jimf-password-white':'input-simples-jimf-password' }`}
                            
                            >

                            <label htmlFor="confirm_password" >
                            Nueva contraseña
                                </label>
                                <div >
                                    <input 
                                              type="password"
                                              id="confirm_password"
                                              name="confirm_password"
                                              placeholder="Confirmar su nueva contraseña"
                                              onChange={onChange}
                                              value={confirm_password}
                                              autoComplete="off"
                                              required
                                        
                                        /> 
                                </div>

                            </div>

                                            <button  type="submit" className={`submit-jimf ${spin === true ? 'padd-mad' :''}`}>
                                            {Loader}
                                                </button>




                            </form>

            </div>


        </div>
        <div className="window-select-tipe">
                <h1>Actualizar mi contraseña</h1>
        </div>


                                                
                {mensajeopen ? <> 
                    <Mensaje
                            open={true}
                            mensaje={mensaje} 
                            guardarMensajeOpen={guardarMensajeOpen}
                            guardarMensaje={guardarMensaje}
                            
                        /> 
                    
                
                </> : null
                
                }


        </>


     );
}
 
export default PasswordReset;