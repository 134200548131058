const FC_targetas = {
  formatCardNumber: (creditCardNumber) => {
    // Remove any non-digit characters from the input string
    const cleanedNumber = creditCardNumber.replace(/\D/g, "");

    // Check if the cleaned number has at least 8 digits
    if (cleanedNumber.length < 8) {
      return "Invalid credit card number";
    }
    // Get the first 4 digits of the credit card number
    const firstFourDigits = cleanedNumber.slice(0, 4);

    // Get the last 4 digits of the credit card number
    const lastFourDigits = cleanedNumber.slice(-4);

    // Calculate the number of asterisks needed between the first and last 4 digits
    const numAsterisks = cleanedNumber.length - 8;

    // Create a string with asterisks
    const maskedDigits = "*".repeat(numAsterisks);

    // Concatenate the masked number parts
    const maskedCreditCardNumber = `${firstFourDigits}${maskedDigits}${lastFourDigits}`;

    return maskedCreditCardNumber;
  },

  // formatDateTarjeta: (dateString) => {
  //   const monthsInSpanish = [
  //     'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
  //     'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
  //   ];
  
  //   const date = new Date(dateString);
  //   const day = date.getUTCDate();
  //   const month = monthsInSpanish[date.getUTCMonth()];
  //   const year = date.getUTCFullYear();
  //   return `${day} ${month}, ${year}`;
  // }
}



module.exports = { FC_targetas }