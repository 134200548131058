import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/autenticacion/authContext.js";
import { FC_targetas } from "views/Tagetas/functions.js";


const DetailsCardModal = ({ data, onClose }) => {

  const regex = /^-/
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
  }

  const formatDateTarjeta = (dateString) => {
    const monthsInSpanish = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
  
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = monthsInSpanish[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `${day} ${month}, ${year}`;
  }

  function limitString(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength); // Adding ellipsis to indicate truncation
  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [panther_load]);

  const authContext = useContext(AuthContext);

  const { PantherNoLoad, PantherLoad, panther_load, recargandoapp } =
    authContext;

  return (
    <>
      <div className="row w-100 m-auto">
        <div className="d-flex justify-content-between w-100 border-bottom-2 border-white px-3 align-items-center">
          <div className="mb-auto">
            <h2 className="">Detalles</h2>
          </div>
          <div className="mb-auto btn-back-tarjetas">
            <i 
              onClick={onClose} 
              className="tim-icons icon-simple-remove"  
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <p className="d-flex justify-content-between align-items-center border-bottom">
            <span className="">Balanço bloqueado: </span>
            <span className="value-description">R${" "}{data.blocked_balance ? '0.00' : data.blocked_balance}</span>
          </p>
          <p className="d-flex justify-content-between align-items-center border-bottom">
            <span>Balanco de transaccíon:</span> 
            <span className="value-description">R${" "} {!data.transit_balance ? '0.00' : data.transit_balance}</span>
          </p>
          <p className="d-flex justify-content-between align-items-center border-bottom">
            <span>Numero da targeta:{" "}</span>
            <span className="value-description">{!data.number ? "0000-0000-0000-0000" : FC_targetas.formatCardNumber(data.number)}</span>
          </p>
          <p className="d-flex justify-content-between align-items-center border-bottom py-3">
            <span>Estatus: </span>
            <span
              className={`${
                data.status !== "APROBADO" ? "saldos-neg" : "saldos-plus"
              } `}
            >
              {" "}
              {data.status}
            </span>{" "}
          </p>
          <p className="d-flex justify-content-between align-items-center border-bottom">
            <span>Creado en:{" "}</span>
            <span className="value-description"> {!data.created_at ? "00/00/0000" : formatDate(data.created_at)} </span>
          </p>
          <p className="d-flex justify-content-between align-items-center border-bottom">
            <span>Atualizado en:{" "}</span>
            <span className="value-description">{!data.created_at ? "00/00/0000" : formatDate(data.updated_at)}</span>
          </p>
        </div>

        <div className="col-12 col-md-6">
          <h2 className="title w-100 border-bottom">transaccíons</h2>
          <ul className="list-group custom-scroll-tarjetas">
            {
              data.movimientos.length > 0 
              ?
              data.movimientos.map((item) => {
                return (
                  <li className="list-item mb-2" key={item.id}>
                    <div className="w-100">
                      <div className="d-flex justify-content-between border-bottom transaction-history-tarjetas">
                        <div className="">
                          <div className="d-flex justify-content-center">
                            <span className="mr-1">{!item?.description ? '--------' : limitString(item?.description, 12) + "..."}</span>{' | '} 
                            <span className="ml-1">{!data.number ? limitString("0000-0000-0000-0000", 9) : limitString(FC_targetas.formatCardNumber(data.number), 9)}</span>
                          </div>
                          <p>Referencia: {!item?.reference ? "--------" : item?.reference}</p>
                          <p>Creado por: {!item?.created_by ? "none" : item?.created_by}</p>
                        </div>

                        <div className="">
                          <p className="">R$ {!item?.amount ? "0.00" : <span className={regex.test(item.amount) ? "text-danger fw-bold amount-value-tarjeta" : "text-success fw-bold amount-value-tarjeta"}>{item?.amount}</span>}</p>
                          <p className="ml-4 mr-0 my-0 date-value-tarjeta">{!item?.created_at ? "0000/00/00" : formatDateTarjeta(item?.created_at)}</p>
                        </div>
                      </div>

                    </div>
                  </li>
                );
              })
              : (
                <li className="list-item">
                    <div className="">
                      <p>Descripción: --------</p>
                      <p>Cantidad: R$ <span>0.00</span></p>
                      <p>Creado por: <span>None</span></p>
                      <p>Referencia: <span>--------</span></p>
                      <p>Creado en: <span>0000/00/00</span></p>
                    </div>
                  </li>
              )

            }
          </ul>
        </div>
      </div>
      
    </>
  );
};

export default DetailsCardModal;
