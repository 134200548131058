import React, { useContext, useState, useEffect, Fragment } from "react";
import LogoWhite from "../../assets/scss/black-dashboard-react/panther/logo-white.svg";
import clienteAxios from "../../config/axios";
import AuthContext from "../../context/autenticacion/authContext";

import PropTypes from "prop-types";

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Spinner from "components/spinners/Spinner";
import { FC_targetas } from "./functions";
import DetailsCardModal from "components/Modales/DetailsCardModal";
import FormEdit from "./FormEdit";
import Transfer from "./Transfer";
import Mensaje from "components/Alertas/Mensaje";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    outline: "none",
    "& > span": {
      maxWidth: 100,
      width: "100%",
      backgroundColor: "#00ff9d",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    alignItems: "center",
    marginRight: theme.spacing(0),
    borderRadius: "30px",
    outline: "none",
    transition: "all 500ms ease",
    padding: "0px 5px 0px 5px",
    margin: "0px 4px 0px 4px",
    alignItems: "center",

    "&:focus": {
      opacity: 0.8,
      color: "#f5f5f5",
      border: "none",
      outline: "none",
      boxShadow: "0px 0px 10px #3969f7",
      tramsition: "all 500ms ease",
      background: "#005eff",
      borderRadius: "30px",
    },
    "&:hover": {
      opacity: 0.6,
      color: "#f5f5f5",
      border: "none",
      outline: "none",
      boxShadow: "0px 0px 10px #3969f7",
      tramsition: "all 500ms ease",
      background: "#000",
      borderRadius: "30px",
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const UserTargetas = (props) => {
  const [selectedCard, setSelectedCard] = useState(null);

  const [selectedCardTrash, setSelectedCardTrash] = useState(null);
  const [openModalDisabledCard, setOpenModalDisabledCard] = useState(false);

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [btnDownloadXML, setBtnDownloadXML] = useState(false)
  const [btnDownloadPDF, setBtnDownloadPDF] = useState(false)


  const authContext = useContext(AuthContext);

  const { status, usuario } = authContext;

  const ApiLoad = () => {
    guardarApiLoad(false);
    setTimeout(() => {
      guardarApiLoad(true);
    }, 3);
  };

  const [apiload, guardarApiLoad] = useState(true);
  //// aqui termina el api load

  const [isLoading, setIsLoading] = useState(true);
  /**/

  const [handleCountries, setHandleCountries] = useState([
    {
      id: "",
      iso: "",
      name: "",
    },
  ]);

  const [tarjetas, setTarjetas] = useState([]);

  const [dataUser, setDataUser] = useState(usuario);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState([]);
  const [reason, setReason] = useState("");

  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  // const [successState, setSuccessState] = useState(false)
  const [mensajeopen, guardarMensajeOpen] = useState(false);
  const [mensaje, guardarMensaje] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [back, setback] = useState(0);
  const [prev, setPrev] = useState(5);
  const [count, setCount] = useState(1);

  const [exportFile, setExportFile] = useState(false);
  const [idFile, setIdFile] = useState(null);

  const [countPage, setCountPage] = useState(null);

  const [wait, setWait] = useState(false);

  const [detailsTarjeta, setDetailsTarjeta] = useState({
    primero: 1,
    segundo: 0,
  });

  const [appbar, guardarAppBar] = useState({
    primero: 1,
    segundo: 0,
    tercero: 0,
  });

  const handleModalDisableCard = (id) => {
    setSelectedCardTrash(id);
    setOpenModalDisabledCard(true);

    const infoDelete = tarjetas.filter((info) => info.id === id)[0];
    setInfo(infoDelete);
    // if(infoDelete.length > 0) {
    // }
  };

  const handleShowDetails = (id) => {
    setSelectedCard(id);
    ActivarPrimeroDetalle();
    guardarAppBar({ ...appbar, primero: 0, segundo: 0, tercero: 0 });
  };

  const handleCloseDetails = () => {
    setSelectedCard(null);
    ActivarSegundoDetalle();
    guardarAppBar({ ...appbar, primero: 1, segundo: 0, tercero: 0 });
  };

  // hide and hide box details

  const ActivarPrimeroDetalle = () => {
    setDetailsTarjeta({
      ...detailsTarjeta,
      primero: 1,
      segundo: 0,
    });
  };

  const ActivarSegundoDetalle = () => {
    setDetailsTarjeta({
      ...detailsTarjeta,
      primero: 0,
      segundo: 1,
    });
  };

  //// luego vamos a colocar las funciones de flecha, para usarlas en el navbar

  const ActivarPrimero = () => {
    guardarAppBar({ ...appbar, primero: 1, segundo: 0, tercero: 0 });
  };

  const ActivarSecundo = () => {
    guardarAppBar({
      ...appbar,
      primero: 0,
      segundo: 1,
      tercero: 0,
    });
    ActivarSegundoDetalle();
  };

  const ActivarTercero = () => {
    guardarAppBar({
      ...appbar,
      primero: 0,
      segundo: 0,
      tercero: 1,
    });
  };

  useEffect( () => {
    const consultarTargetas = async () => {
      try {  
        const respuesta = await clienteAxios.post("/api/card/list");
        const tt = respuesta.data.data.length;
        const total = Math.ceil(tt / 5);
        setCountPage(total)

        setTarjetas(respuesta.data.data);
        setIsLoading(false);

        const countries = await clienteAxios.post("/api/paises/list");
        setHandleCountries(countries.data.data);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    consultarTargetas();
  }, []);

  const classes = useStyles();
  const theme = useTheme();

  const [profilechange, guardarProfileChange] = useState({
    first_name: "",
    last_name: "",
    document_type: "",
    cedula: "",
    email: "",
    birthDate: "",
    phone: "",
    genero: "",
    estadocivil: "",
    nacionalidad: "",
    address: "",
    state: "",
    country_id: "",

    city: "",
    zipCode: "",
    profesion: "",
  });

  const onChange = (e) => {
    guardarProfileChange({
      ...profilechange,
      [e.target.name]: e.target.value,
    });
  };

  const mount = tarjetas.length;

  const handleBack = () => {
    if (back >= 5) {
      setback((back) => back - 5);
      setPrev((prev) => prev - 5);
      const values = count - 1;
      setCount(values);
    }
  };

  const handleNext = () => {
    if (prev < mount) {
      setback((back) => back + 5);
      setPrev((prev) => prev + 5);
      const values = count + 1;
      setCount(values);
    }
  };

  const handleDeleteCard = async (card_id, reason) => {
    const data = {
      card_id: card_id,
      reason: reason,
    };
    setWait(!false);
    
    try {
      await clienteAxios.post("/api/card/change", data).then((res) => {
        
        if (res.data.success) {
          setSuccessMessage("Tarjeta desactivada con éxito!");
          setShowMessage(!false);
          setWait(false);
        }
      });
    } catch (error) {
      setSuccessMessage(error);
      setShowMessage(!false);
    }
  };

  const handleExportFiles = (id) => {
    setIdFile(id);
    setExportFile(!false);
  };

  const handleLink = async (link) => {
    window.open(link, '_blank')
  }

  const handleExportXML = (id, type, from, to) => {
    tarjetas
    .filter((item) => item.id === id)
    .map(async (tar) => {
      if(tar.movimientos.length === 0) {
        return null
      } else {
        setBtnDownloadXML(!false)
        const data = {
          "card_id": id,
          "type":type, 
          "from": from,
          "to": to
        }
    
        await clienteAxios.post("/api/card/movements", data)
          .then((res) => {
            console.log(res)
            if (res.data.success) {
              setTimeout(async () => {
                setBtnDownloadXML(false)
                await handleLink(res.data.data)
              }, 1000)
            } else return null
    
          });
      }
    })
    
    
  };

  const handleExportPDF = (id, type, from, to) => {
      tarjetas
      .filter((item) => item.id === id)
      .map(async (tar) => {
        if(tar.movimientos.length === 0) {
          return null
        } else {
          setBtnDownloadPDF(!false)
        const data = {
          "card_id": id,
          "type":type, 
          "from": from,
          "to": to
        }

        await clienteAxios.post("/api/card/movements", data)
          .then((res) => {
            if (res.data.success) {
              setTimeout(async () => {
                setBtnDownloadPDF(false)
                await handleLink(res.data.data)
              }, 1000)
              
            }

          });  
        }
      })
  };

  const handleUpdate = async (updateData) => {
    
    const countrySelected = handleCountries.filter((country) => {
      return country.name === profilechange.address_country;
    });
    const firstName = !profilechange.first_name
      ? updateData.name.split(" ")[0]
      : profilechange.first_name;
    const lastName = !profilechange.last_name
      ? updateData.name.split(" ")[1]
      : profilechange.last_name;
    const document_type = !profilechange.document_type
      ? "Cédula/DNI"
      : profilechange.document_type;
    const cedula = !profilechange.cedula
      ? updateData.identity
      : profilechange.cedula;
    const email = !profilechange.email ? updateData.email : profilechange.email;
    const birthDate = !profilechange.birthDate
      ? updateData.birthday_date
      : profilechange.birthDate;
    const phone = !profilechange.phone
      ? updateData.telephone
      : profilechange.phone;
    const genero = !profilechange.genero ? "" : profilechange.genero;
    const estadocivil = !profilechange.estadocivil
      ? ""
      : profilechange.estadocivil;
    const nacionalidad = !profilechange.nacionalidad
      ? ""
      : profilechange.nacionalidad;
    const address = !profilechange.address
      ? updateData.address_number + " " + updateData.address_street
      : profilechange.address;
    const state = !profilechange.state
      ? updateData.address_city
      : profilechange.state;
    const city = !profilechange.city ? updateData.address_city : profilechange.city;
    const zipCode = !profilechange.zipCode
      ? updateData.address_zipcode
      : profilechange.zipCode;
    const profesion = !profilechange.profesion
      ? "Autónomo"
      : profilechange.profesion;
    const country_id = !profilechange.country_id
      ? ""
      : profilechange.country_id;

    const data = {
      "first_name": firstName,
      "last_name": lastName,
      "document_type": document_type,
      "cedula": cedula,
      "email": email,
      "birthDate": birthDate,
      "phone": phone,
      "genero": genero,
      "estadocivil": estadocivil,
      "nacionalidad": nacionalidad,
      "address": address,
      "state": state,
      "country_id": country_id,
      "city": city,
      "zipCode": zipCode,
      "profesion": profesion,
    };

    const emptyFields = Object.keys(data).filter((key) => !data[key]);

    if (emptyFields.length > 0) {
      const errorMessage = `Los siguientes campos son requeridos: ${emptyFields.join(
        ", "
      )}`;
      setErrorMessage(errorMessage);
      setEditing(!false);
      return;
    } else {
      setLoading(!false)
      try {
        await clienteAxios.post("/api/card/create", data).then((res) => {
          if (res.data.success) {
            setErrorMessage("¡Datos actualizados con éxito! \n Espera por aprovacion.")
            setEditing(!false)
            setLoading(false)
            const timeOut = setTimeout(() => {
              clienteAxios.post("/api/card/list").then(async (dataUpdate) => {
                setTarjetas(dataUpdate.data.data)
              })
            }, 2000)
            clearTimeout(timeOut)
          }
        });
        
      } catch (error) {
        setErrorMessage(error)
        setEditing(!false)
      }
    }
  };

  return (
    <>
      <div className="content position-relative">
        <div className={classes.root}>
          <>
            <div className="grid-app-bar-simple  mb-2 ">
              <div className="grid-items-2">
                <p
                  className={` ${
                    appbar.primero === 1 ? "active-selection-proyectos" : ""
                  }`}
                  onClick={ActivarPrimero}
                >
                  {" "}
                  Lista de Tarjetas
                  <h4></h4>
                </p>

                <p
                  className={` ${
                    appbar.tercero === 3 ? "active-selection-proyectos" : ""
                  }`}
                  onClick={ActivarTercero}
                >
                  Transferir
                  <h4></h4>
                </p>

                <p
                  className={` ${
                    appbar.tercero === 3 ? "active-selection-proyectos" : ""
                  }`}
                  onClick={ActivarSecundo}
                >
                  Solicitar Tarjeta
                  <h4></h4>
                </p>
              </div>
            </div>
          </>

          {appbar.segundo === 1 && (
            <div className="position-relative">
              <FormEdit
                data={dataUser}
                onUpdate={handleUpdate}
                onDataChange={onChange}
                errorMessage={errorMessage}
                editState={editing}
                setEditState={() => setEditing(false)}
                loading={loading}
              />
            </div>
          )}

          {status === 3 ? (
            isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <div className="m-auto">
                  <Spinner />
                </div>
              </div>
            ) : (
              <>
                {/* SHOW AND HIDE DETAILS FROM CARD */}

                {selectedCard !== null && (
                  <div className="">
                    {appbar.primero === 0 && detailsTarjeta.primero === 1 && (
                      <>
                        <div className="postision-relative">
                          <div className="ultimos-grid-movimientos animation-cuentas cursor-pointer">
                            <DetailsCardModal
                              data={
                                tarjetas.filter(
                                  (detail) => detail.id === selectedCard
                                )[0]
                              }
                              onClose={() => handleCloseDetails()}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {appbar.primero === 1 && (
                  <div className="grid-table-targetas mb-2 position-relative">
                    {/* HANDLE DELETE CARD */}
                    {openModalDisabledCard && (
                      <div className={"Mensaje  "}>
                        {showMessage ? (
                          <div className={"Mensaje  "}>
                            <div className="mensaje-box">
                              <img
                                className="logo-size-2"
                                src={LogoWhite}
                                alt=""
                              />
                              <h2>{successMessage}</h2>
                              <div className="btn-box-panther-mensaje">
                                <button
                                  className="btn-panther"
                                  onClick={() => setShowMessage(false)}
                                >
                                  Ok
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="mensaje-box">
                            {wait ? (
                              <Spinner />
                            ) : (
                              <img
                                className="logo-size-2"
                                src={LogoWhite}
                                alt=""
                              />
                            )}

                            <h2>
                              ¿Seguro que quieres desactivar esta tarjeta?
                            </h2>
                            <h1>
                              {!info
                                ? "Não podemos deletar"
                                : `${
                                    !info.card_model
                                      ? "No card"
                                      : info.card_model
                                  } - ${
                                    !info.number
                                      ? "0000********0000"
                                      : FC_targetas.formatCardNumber(
                                          info.number
                                        )
                                  }`}
                            </h1>
                            <div className="input-size-jimf">
                              <label>Reason</label>

                              <div className="box-data-complete-transfer">
                                <input
                                  type="text"
                                  id="reason"
                                  name="reason"
                                  onChange={(e) => setReason(e.target.value)}
                                  defaultValue={reason}
                                  placeholder={"Describa la razón aquí"}
                                />
                              </div>
                            </div>
                            <div className="btn-box-panther-mensaje">
                              <button
                                className="btn-panther my-3 mr-md-3"
                                onClick={() =>
                                  handleDeleteCard(info.id, reason)
                                }
                              >
                                confirmar
                              </button>
                              <button
                                className="btn-panther my-3"
                                onClick={() => setOpenModalDisabledCard(false)}
                              >
                                Cancelar
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {/* HANDLE EXPORT FILES */}

                    {exportFile && (
                      <div className="Mensaje position-relative">
                      <div className="mb-auto btn-back-tarjetas btn-close-file">
                        <i 
                          onClick={() => setExportFile(false)}
                          className="tim-icons icon-simple-remove"
                          style={{fontSize: '28px'}}  
                        />
                      </div>
                        <div className="mensaje-box">
                          <img className="logo-size-2" src={LogoWhite} alt="" />
                          <h2>Escoge una opción</h2>
                          <div className="btn-box-panther-mensaje">
                            <div className="d-flex gap-2 box-data-complete">
                              <div className="input-size-jimf">
                                <label>Desde</label>
                                <input
                                  type="date"
                                  id="from"
                                  name="from"
                                  onChange={(e) => setDateFrom(e.target.value)}
                                  defaultValue={dateFrom}
                                  placeholder={"Desde"}
                                />
                              </div>

                              <div className="input-size-jimf">
                                <label>Hasta</label>
                                <input
                                  type="date"
                                  id="to"
                                  name="to"
                                  onChange={(e) => setDateTo(e.target.value)}
                                  defaultValue={dateTo}
                                  placeholder={"Hasta"}
                                />
                              </div>
                            </div>
                            <div className="d-flex gap-2 align-items-center justify-content-around">
                              <button
                                className="btn-panther my-3 mr-md-3"
                                onClick={() => handleExportXML(idFile, "xls", dateFrom, dateTo)}
                              >
                                {
                                  btnDownloadXML ? (<Spinner />) : (
                                    <>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-filetype-xml"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992 1.274-2.007Zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999h.706Zm4.71-.674h1.696v.674H8.4V11.85h.791v3.325Z"
                                        />
                                      </svg>{" "}
                                      XML
                                    </>
                                  )
                                }
                                
                              </button>
                              <button
                                className="btn-panther my-3"
                                onClick={() => handleExportPDF(idFile, "pdf", dateFrom, dateTo)}
                              >
                                {
                                  btnDownloadPDF ? (<Spinner />) : (
                                    <>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-file-pdf"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                        <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                      </svg>{" "}
                                      PDF
                                    </>
                                  )
                                }
                              </button>
                            </div>
                          </div>
                              {/* <button
                                className="btn-panther my-3"
                                onClick={() => setExportFile(false)}
                              >
                                Salir
                              </button> */}
                        </div>
                      </div>
                    )}
                    
                    <div className=" box-grid">
                      <div className="d-flex flex-md-row justify-content-around">
                        <strong className="line-fix-targeta py-2">
                          <i className="tim-icons icon-credit-card p-1" />{" "}
                          TARJETA
                        </strong>
                        <strong className="line-fix-targeta py-2">
                          <i className="tim-icons icon-puzzle-10 p-1" /> Numero
                        </strong>
                        <strong className="line-fix-targeta py-2">
                          <i className="tim-icons icon-paper p-1" /> Status
                        </strong>
                        <strong className="line-fix-targeta py-2 text-right align-items-flex-end">
                          <i className="tim-icons icon-bank p-1" /> Saldo
                        </strong>
                        <strong className="line-fix-targeta py-2 text-right align-items-flex-end">
                          <i className="tim-icons icon-settings-gear-63 p-1" />{" "}
                          Accion
                        </strong>
                      </div>

                      <div className=" d-flex w-100 flex-column">
                        {tarjetas
                          .map((tarjeta, index) => (
                            <>
                              <div
                                className="ultimos-grid-movimientos animation-cuentas cursor-pointer"
                                key={tarjeta.id}
                              >
                                <div className="row-targetas">
                                  <div className="d-flex flex-md-row justify-content-around">
                                    <div className="d-md-flex gap-2">
                                      <i
                                        className="tim-icons icon-credit-card p-1"
                                        style={{ fontSize: "12px" }}
                                      />
                                      <p>
                                        {" "}
                                        <span
                                          className="text-primary text-md"
                                          data-tooltip={tarjeta.card_model}
                                        >
                                          {tarjeta.card_model}
                                        </span>
                                      </p>
                                    </div>

                                    <div>
                                      <p>
                                        {" "}
                                        {tarjeta.number === null
                                          ? `0000********0000`
                                          : `${FC_targetas.formatCardNumber(
                                              tarjeta.number
                                            )}`}{" "}
                                      </p>
                                    </div>

                                    <div>
                                      <p>
                                        <span
                                          className={`
                                                    ${
                                                      tarjeta.status !==
                                                      "APROBADO"
                                                        ? "saldos-neg"
                                                        : "saldos-plus"
                                                    }
                                                  `}
                                        >
                                          {" "}
                                          {tarjeta.status}
                                        </span>{" "}
                                      </p>
                                    </div>

                                    <div>
                                      <p>
                                        <strong>R$ {tarjeta.balance} </strong>
                                      </p>
                                    </div>
                                    <div>
                                      <p>
                                        <button
                                          title="DETALLE"
                                          className="btn-details-targeta"
                                          onClick={() =>
                                            handleShowDetails(tarjeta.id)
                                          }
                                        >
                                          <i
                                            className="tim-icons icon-bullet-list-67"
                                            style={{ fontSize: "14px" }}
                                          />
                                        </button>

                                        <button
                                          className="btn-details-targeta ml-md-2"
                                          onClick={() =>
                                            handleExportFiles(tarjeta.id)
                                          }
                                          disabled={tarjeta.movimientos.length == 0  ? true : false} 
                                          title={tarjeta.movimientos.length == 0 ? "NINGUMA TRANSACCIÓN" : "EXPORTAR TRANSACION"}
                                        >
                                          <i
                                            className={tarjeta.movimientos.length == 0 ? 'tim-icons icon-paper' : 'tim-icons icon-cloud-download-93'}
                                            style={{ fontSize: "14px" }}
                                          />
                                        </button>

                                        <button
                                          title="DESABILITAR TARJETA"
                                          className="btn-details-targeta ml-md-2"
                                          onClick={() =>
                                            handleModalDisableCard(tarjeta.id)
                                          }
                                        >
                                          <i
                                            className="tim-icons icon-trash-simple"
                                            style={{ fontSize: "14px" }}
                                          />
                                        </button>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))
                          .slice(back, prev)}
                      </div>
                    </div>

                    <div className="header">
                      <div class="grid-container-paginador">
                        <button
                          title=""
                          class="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                          disabled=""
                          onClick={() => handleBack()}
                        >
                          « Anterior
                        </button>
                        <div class="pagination-style">
                          Página <strong>{count}</strong> de{" "}
                          <span>{countPage}</span>
                        </div>
                        <button
                          title=""
                          disabled=""
                          class="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                          onClick={() => handleNext()}
                        >
                          Siguiente »
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {appbar.tercero === 1 && <Transfer />}
              </>
            )
          ) : null}
        </div>
      </div>
    </>
  );
};

export default UserTargetas;
