import React, { useState, useContext, useEffect, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {ReactComponent as Logo} from '../logo.svg';

import { createBrowserHistory } from "history";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import AuthContext from '../../context/autenticacion/authContext.js';
import Alerta from '../Alerta';


import Spinner from '../spinners/Spinner'


import ModalMensaje from '@material-ui/core/Modal'
//// vamos a importar el modal


// importacion del modal
import Modal from '@material-ui/core/Modal'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'; 



///// aqui vammos a importar el style component

import styled from 'styled-components'
 

/// aqui vamos a importar como el modal entra 
/// fade del modal
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade'; 

/// aqui vammos a importar el proptypes
import PropType from  'prop-types'; 



/// aqui vamos a importar los estylos del input de material ui

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
/// aqui vamos a colocar la informacion del check y los estilos

import { 
    Row,
    Col,
    CardBody,
    CardHeader,
    CardFooter,
  } from "reactstrap"; 





function getModalStyle() {
    const top = 50 ;
    const left = 50;
  
    return {
      top: ``,
      left: ``,
      transform: ``,
      
      
    };
  }
  
  
  const useStyles = makeStyles((theme) => ({
        modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'hidden',
    height:'100%',
    
    background: '#0000005c',
      
    },
    paper: {
      background: '#001c52',
      border: '2px solid #04a1ff',
      boxShadow: theme.shadows[5],
      
      
    },
  }));
  
  
  
   


const NuevaCuenta = (props) => { 

    document.oncontextmenu = function() {
        return false
     }

     function right(e) {
        var msg = "¡Hola Panthera aquí no puedes usar Click Derecho!";
        if (navigator.appName == 'Netscape' && e.which == 3) {
            // - Si no quieres asustar a tu usuario entonces quita esta linea...
            
           return false;
        }
        else if (navigator.appName == 'Microsoft Internet Explorer' && e.button==2) {
        return false;
        }
            return true;
        }; 

        document.onmousedown = right;  
    

    const authContext = useContext(AuthContext);
    const { mensaje, autenticado, registrarUsuario, userStatus, Limpiar , LimpiarMensaje, demo_status } = authContext;

    
 const [openmensaje, setOpenMensaje] = useState(true);

 

 const handleOpenMensaje = () => {
     setOpenMensaje(true);
 } 

 const handleCloseMensaje = () => {
     setOpenMensaje(true);
     guardarMensaje(null);
 }


const [registromensaje, guardarRegistroMensaje ] = useState('');

    const hist = createBrowserHistory();

    const [mensajedos, guardarMensaje] = useState(''); 

    const [ elcargando, elGuardarCargando] = useState(false);

    const elguardar = ( elcargando ) ? <Spinner/> :  
                                            
    <Fragment>
      ¡Abrir mi cuenta!
      </Fragment> 
  
  

 // aqui vamos a importar los estados del modal
 const [ modalStyle ] =useState(getModalStyle);
 const [open, setOpen] =useState(false);

 

 const handleOpen = () => {
     setOpen(true);
 } 

 const handleClose = () => {
     setOpen(false); 
     guardarMensaje(null);
 } 


///// esto es el modal de mensaje


 
//// aqui vamos a colocar los estados 

const classes = useStyles();
  const [state, setState] = React.useState({
    
    terminos: false,
    
  });

  

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const {  terminos } = state;
  const error = [terminos].filter((v) => v).length !== 2;




    //extraer los valoresa del context  para la alerta



    // ahora vamos a extraer los valores de registrar usuario del context

    

    //// aqui vamos a hacer un redirect con una opcion true o false

    const [ registrarseclick, setRegistrarseClick ] = useState(false);




    // en caso de que el usuario se haya autenticado 
    // despues del rendering vamos a autenticar el usuario y pasarle las propiedades con los props
    useEffect( () => {

        // if(autenticado) {
        //     props.history.push('/cliente');
        // } 

        // if(autenticado) {
        //     userStatus({ email,password});
        // } 

        // if(registrarseclick) {
           
      
        //         props.history.push('/');

           
        // } 

        if (mensaje) {
            guardarRegistroMensaje(mensaje)
            elGuardarCargando(false);
        }


    } , [mensaje,autenticado,registrarseclick, props.history]
    );
 
    // estamos creando el state
    const [usuario, guardarUsuario] = useState({
        name:'',
        email:'',
        password: '',
        confirmar:'',
        referido:'',
        origen:'',
    });

    // extraer usuario del state

    const {name,email,password,confirmar,referido, origen} = usuario;

    


    const onChange = e => {

        // voy a colocar guardar usuario en el state

        guardarUsuario({
            ...usuario,
            [e.target.name] : e.target.value

        })
    }

    const [ mostrarpassword , setMostrarPassword] = useState(false)


    /// cuando el usuario quiere iniciar seseion
    const onSubmit = e => {
        elGuardarCargando(true)
        e.preventDefault();

     

        //     if( name.trim() === '' || 
        //         email.trim() === '' || 
        //         password.trim() === '' || 
        //         confirmar.trim() === ''  ) {
        //             {mensaje}
        //         } 

        // if (name.trim() === '') {
        //     mostrarAlerta('Coloca tu nombre', 'alerta-panther');
        // }
        
        // // vamos a poner que el password minimo debe tener 6 caracteres
        // if (password.length < 6) {
        //     mostrarAlerta('El Password debe ser de al menos 6 caracteres', 'alerta-panther');
        // }

        // // los 2 password tienen que ser iguales
        // if (password !== confirmar) {
        //     mostrarAlerta('Los password no son iguales','alerta-panther');
        // }




        // luego lo vamos a pasar al action



        // setTimeout( () => {
              
        //     setRegistrarseClick(true)
            
        //     },10000)
  

        if (terminos === true) {
            

            registrarUsuario({
                referido,
                name,
                email,
                password,
                referido,
                origen
            }); 

           

            
        } 
        
       
        
        
        

    }



    return ( 

        <Fade in={open}
        onClose={handleClose}
        timeout={1000}
        
        
        >  
        <Fragment>
        <div className="form-usuario">
            
            <div className="contenedor-form  sombra-dark ">

                <Logo className="animation-icon-in"/>
                <h1 className="login-font font-fix-register animation-icon-in-dos">¡Crear mi cuenta!</h1>
                
                
                    <form onSubmit={onSubmit} className="mb-4"> 

                    {/* <h1 className="beta-text mb-0">Beta</h1>
                        <p className="text-dark text-center">Bienvenido a la version Beta de <strong> www.Panther7.com</strong></p> */}

                    <div className="campo-form-panther-joe mt-4 animation-icon-in-cinco">
                                <label htmlFor="referido" className="text-left" >Referido</label>

                                <input
                                    type="text"
                                    id="referido"
                                    name="referido"
                                    placeholder="ID de Referido (si aplica)"
                                    onChange={onChange}
                                    value={referido}
                                    />
                        </div>

                        

                    <div className="campo-form-panther-joe animation-icon-in-cinco">
                                <label htmlFor="name" className="text-left">Nombre y apellido</label>

                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Su nombre y apellido"
                                    onChange={onChange}
                                    value={name}
                                    autoComplete="off"
                                    required
                                    />
                        </div>


                        <div className="campo-form-panther-joe animation-icon-in-cinco">
                                <label htmlFor="email"className="text-left" >Correo electrónico </label>

                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Su correo electrónico"
                                    onChange={onChange}
                                    value={email}
                                    autoComplete="off"
                                    required
                                    />
                        </div>

                        <div className="campo-form-panther-joe animation-icon-in-cinco"> 

                                                        

                                                                    <label htmlFor="password" className="text-left" >Contraseña</label>
                                                                {mostrarpassword ?  
                                                                <div className="d-flex align-items-center pantherpassword"> 
                                                                

                                                                <input
                                                                    type="text"
                                                                    id="password"
                                                                    name="password"
                                                                    placeholder="Mi contraseña                 "
                                                                    onChange={onChange}
                                                                    value={password}
                                                                    // className="input-panther7 "
                                                                    
                                                                    required
                                                                    autocomplete="true"
                                                                    
                                                                    />
                                                                        
                                                                    <i  
                                                                        onClick={ () => ( 

                                                                            setMostrarPassword(false)

                                                                        )}
                                                                    
                                                                    
                                                                    >
                                                                        <VisibilityIcon className="icon-vis-style" />
                                                                        
                                                                    </i> 

                                                                </div> : 
                                                                
                                                                
                                                                <div className="d-flex align-items-center pantherpassword"> 
                                                                

                                                                <input
                                                                    type="password"
                                                                    id="password"
                                                                    name="password"
                                                                    placeholder="Mi contraseña                      "
                                                                    onChange={onChange}
                                                                    value={password}
                                                                    // className="campo-form-panther "
                                                                    required
                                                                    autocomplete="true"
                                                                    
                                                                    />

                                                                

                                                                    <i 
                                                                        onClick={ () => ( 

                                                                            setMostrarPassword(true)

                                                                        )}
                                                                    
                                                                    
                                                                    >
                                                                        <VisibilityOffIcon className="icon-vis-style"/>
                                                                    </i> 

                                                                </div> 
                                                                
                                                            } 





                                                        


                                                        </div>

                        <div className="campo-form-panther-joe animation-icon-in-cinco">
                                <label htmlFor="confirmar"className="text-left" >Confirmación de contraseña</label>

                                <input
                                    type="password"
                                    id="confirmar"
                                    name="confirmar"
                                    placeholder="Confirmar su contraseña"
                                    onChange={onChange}
                                    value={confirmar}
                                    autoComplete="off"
                                    required
                                    
                                    />
                        </div> 

                        <div className="campo-form-panther-joe mt-2">

                            <label className="text-left">Donde nos conociste?</label>


                        <select className="control-joe  text-center " 
                                                                                          onChange={onChange}
                                                                                           value={origen} 
                                                                                          id="origen"
                                                                                          name="origen"
                                                                                          required
                                                                                          
                                                                                  
                                                                                  >
                                                                                  <option  value="" className="animation-cool" > - - - Haga Clic & Seleccione - - -</option>
                                                                                      <option value="Instagram"   >Instagram</option>
                                                                                      <option value="Facebook"   >Facebook</option>
                                                                                      <option value="Email"  >Email</option>
                                                                                      <option value="Youtube"  >Youtube</option>
                                                                                      <option value="Google"   >Google</option>
                                                                                      <option value="Recomendación"  >Recomendación</option>
                                                                                      <option value="Publicidad en Radio/TV"  >Publicidad en Radio/TV</option>
                                                                                      <option value="Un Amigo/a"  >Un Amigo/a</option>
                                                                                  </select>


                        </div>

                        

                      

                        
                                
                                
                                <div className={ `Checkpanther ${classes.root}`}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormGroup>
                                        
                                            <FormControlLabel
                                                control={<Checkbox checked={terminos} onChange={handleChange} name="terminos" required
                                                color="primary"
                                                 
                                                 />}
                                                label="Estoy de acuerdo con los términos y condiciones"
                                                className="checkbox-fixdos"
                                                onClick={ () => {
      
                                                    if (terminos === false) {
                                                        handleOpen()
                                                    }
                                                   
                                                  }}
                                            />
                                    
                                            </FormGroup>
                                            
                                        </FormControl>
                                </div>
                              

                        <div>
                        <button type="submit" className="button-jimf "  >
                                {elguardar}
                                </button>

                                <p className="text-dark-50 w-100 text-center">Su registro estará sujeto a un proceso de validación de datos y documentos</p>
                             
                                    
                        </div> 

                      

                            
                    
                        
                    </form>

                    <Link to={"/login"} className="enlace-cuenta">
                        <p>¿Ya tiene una cuenta <span>Panther7</span>? <span class="entrar">Entrar a su cuenta</span></p>
                    </Link>

            </div>

            {demo_status === "active" ? <p className="Hola">PLATAFORMA DEMO</p> :null }
                                                  
        </div> 

        <Modal
                    
                    className={classes.modal}
                    open={open} 
                    
                    disableScrollLock={true}
                    
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 900,
                    }}
                    >
                    <Fade in={open}
                    timeout={154}
                    onClose={handleClose}>
                        <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                        <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}> 

                        <CardHeader> 
                              
                              <h3 className="title display-4 mb-0 text-center">BIENVENIDO </h3>
                              <p className="text-center">¡Esta muy cerca de completar el registro de su cuenta!</p>
                              
                              <hr class="my-1 border-info"/> 
                              
                            </CardHeader>
                        
                        <div className="terminos-modal-fix">
                                            <Row className="d-flex justify-content-center">
                                                    <Col sm="12" md="11" className=" static-height"> 
                                                    <h1 className="text-center">TÉRMINOS Y CONDICIONES GENERALES DE USO DE LA PLATAFORMA WEB "PANTHER7"</h1>


                                                        <p> Los presentes Términos y Condiciones Generales de uso establecerán los términos y condiciones aplicables al uso de los contenidos, 
                                                        productos y servicios ofrecidos a través de la plataforma web PANTHER7 (https://www.panther7.com), en adelante, PLATAFORMA DE PANTHER 7 "PANTHER 7". Cualquier persona que desee acceder, 
                                                        afiliarse, invertir, financiarse o hacer uso de la plataforma o planes de servicio que en ella se ofrecen, podrá hacerlo sujetándose a los presentes TÉRMINOS Y CONDICIONES, así como las políticas y principios incorporados al presente documento. En caso de que alguna persona no acepte.  </p>

                                                        <h2 className="mt-5 mb-0">Condiciones Generales</h2>
                                                        <hr className="bg-info"/>



                                                    <p> 

                                                    PANTHER7 es un producto de ESSENTIAL FINANCIAL INVESTMENT CORP, una corporación registrada en los Estados Unidos de América con licencia emitida por Florida Office of Financial Regulation (OFR) con numero FT 230000130 con oficinas en 1001 Brickell Bay Drive, Miami, Florida, la cual posee presencia en diversos países a partir de alianzas estratégicas establecida con agentes autorizados locales para la comercialización de la plataforma.

Esta Política de Privacidad informa a las personas registradas en el sitio web www.panther7.com de PANTHER7, en adelante la "Plataforma" y la aplicación de:
a. La "Aplicación de PANTHER7, “PANTHER7"
b. Como usuarios en adelante "Usuarios" 
c. Los que utilizan los servicios proporcionados por PANTHER7, "Servicios" sobre la recogida, uso y transferencia de sus datos personales a terceros.
 
LA ACEPTACIÓN DE LOS TÉRMINOS DE USO Y LA POLÍTICA DE PRIVACIDAD IMPLICARÁN EL RECONOCIMIENTO DEL USUARIO QUE HA LEÍDO, COMPRENDIDO Y ACEPTADO INCONDICIONALMENTE TODAS LAS DISPOSICIONES RELATIVAS A ESTOS DOCUMENTOS. SI TIENE ALGUNA PREGUNTA SOBRE ESTOS TÉRMINOS DE USO Y / O LA POLÍTICA DE PRIVACIDAD, SE PIDE QUE CONTACTE CON PANTHER 7 ANTES DE ACEPTAR Y ESTAR SUJETO A SUS NORMAS.
 
Al aceptar este acuerdo el Usuario acepta los Términos de uso y la Política de privacidad, se aplicarán las disposiciones contenidas en los mismos y regirán plenamente la relación entre PANTHER 7 y el Usuario. Por lo tanto, se recomienda imprimir una copia de ellos para referencia futura.
 
Las actividades de tratamiento de datos personales llevadas a cabo por PANTHER7 no solo se detallan en esta Política de privacidad, sino que también pueden describirse en las Condiciones generales de la plataforma, en el Acuerdo de usuario de la plataforma (en adelante "Acuerdo"), y en la sección Cookies de la Política de privacidad.
 
Si tienes alguna pregunta relativa a la Política de privacidad de PANTHER7 o si quieres obtener información adicional sobre cómo ejercer los derechos especificados en este documento, contacta con la delegada de protección de datos de PANTHER7 hazlo: 
 
CONTACTO:
Correo: Support@panther7.com
Tel: (+507) 3235444

PERSONALMENTE:
Torre de Oceanía 2000 Piso 44, Ciudad de Panamá, Panamá.
Horario: 9:00 – 17:00 Lunes a Viernes
                                                    </p>
                                                    

                                                        <h2 className="mt-5 mb-0">PRESENTACION DE PANTHER7</h2>
                                                        <hr className="bg-info"/> 

                                                        <p> 
                                                        PANTHER 7 es una plataforma digital independiente que media las ventas de activos no bursátiles como, Facturas, proyectos o productos y servicios entre inversionistas y oferentes a través del sitio web (www.panther7.com) y / o aplicación. 
Para productos de inversión, tenga en cuenta que las estimaciones presentadas se basan únicamente en proyecciones. En consecuencia, usted acepta que todas las decisiones que tome con respecto a los productos ofrecidos serán de su exclusiva responsabilidad. Seguimos mejorando y desarrollando constantemente nuevos productos y servicios, buscando mejor atenderlo.
Cualquier información que la empresa pueda anunciarle no constituye un consejo de inversión, simplemente tiene la intención de mantenerlo informado.



                                                        </p>  

                                                        <h2 className="mt-5 mb-0">POLÍTICA DE TRATAMIENTO DE DATOS</h2>
                                                        <hr className="bg-info"/>  

                                                        <p> Con la aceptación de esta autorización, manifiesto que he sido informado por Panther7 y sus empresas asociadas, en adelante las “Compañías” que:

a. Las Compañías actuarán como responsables del Tratamiento de datos personales de los cuales soy titular y que, conjunta o separadamente podrán recolectar, usar, transferir, transmitir y tratar datos personales conforme la Política de Tratamiento de Datos Personales de las Compañías disponible en www.panther7.com.

b. Es de carácter facultativo responder preguntas que versen sobre Datos Sensibles o sobre menores de edad.

c. El titular del dato sensible no está obligado autorizar su tratamiento por parte de las Compañías.

d. Mis derechos como titular de los datos son los previstos en la Constitución y la ley, especialmente el derecho a conocer, actualizar, rectificar y suprimir mi información personal, así como el derecho a revocar el consentimiento otorgado para el tratamiento de datos personales y datos sensibles.

e. Los derechos pueden ser ejercidos a través de los canales gratuitos dispuestos por las Compañías y observando la Política de Tratamiento de Datos Personales de las Compañías. 

f. Para cualquier inquietud o información adicional relacionada con el tratamiento de datos personales, puedo contactarme al correo electrónico support@panther7.com o al teléfono +507-323.5444.

g. Las Compañías garantizan la confidencialidad, libertad, seguridad, veracidad, transparencia, acceso y circulación restringida de los datos personales y se reservan el derecho de modificar su Política de Tratamiento de Datos Personales en cualquier momento.
Cualquier cambio será informado y publicado oportunamente en la página web o a través de los medios que disponga para tal fin. La información obtenida para el Tratamiento de mis datos personales la he suministrado de forma voluntaria y es verídica.

Datos Personales y Política de Privacidad
Al registrarse en el Sitio y / o la Aplicación, el Usuario acepta la Política de Privacidad y al aceptar la Política de Privacidad, el Usuario otorga a PANTHER7 y la AUTORIZACIÓN de la Institución Socia para usar sus datos de acuerdo con las reglas, contenido en estos Términos de uso y Política de privacidad Al emitir su “Acuerdo" al registrarse en el Sitio y / o la Aplicación, el Usuario autoriza a PANTHER7 y a la Institución asociada, si corresponde, a revelar, compartir e intercambiar su información con cualquier otro tercero con el fin de registro, análisis de riesgo de crédito y cualquier otro propósito, incluido, entre otros, el comercio de PANTHER7, la institución asociada o sus productos de terceros.
El Usuario autoriza a PANTHER7 y a la Institución asociada, si corresponde, a verificar los datos proporcionados en el registro y a obtener y proporcionar dicha información a las empresas de análisis y protección de crédito y al Sistema de información crediticia, incluida la información derivada de la misma de operaciones de crédito bajo su responsabilidad, con el propósito de evaluar el riesgo de crédito e intercambiar información con otras instituciones financieras. El Usuario declara ser consciente de que, en caso de incumplimiento de cualquier obligación asumida, se promoverá el registro en los organismos competentes. 


                                                        </p> 


                                                        <h2 className="mt-5 mb-0">REGISTRO DE USUARIO</h2>
                                                        <hr className="bg-info"/>   

                                                        <p>Para registrarse en el Sitio, el usuario primero debe leer y aceptar los "Términos de uso y la Política de privacidad" de PANTHER 7 y solo después de registrarse. El registro del usuario se realiza completando la información solicitada en el sitio, en particular: correo electrónico, nombre y dirección, que serán validados por el sistema local.
* Solo se permite un registro por correo electrónico por persona o entidad legal.
** El registro no está permitido para personas menores de dieciocho (18) años de edad.
PANTHER7 no es responsable de la información proporcionada por cada usuario en el momento del registro, esta información se proporciona bajo la exclusiva responsabilidad del usuario.
El usuario es civil y penalmente responsable de proporcionar todos sus datos, actualizados y completos. Los usuarios también son conscientes de que proporcionar información falsa o hacer mal uso de datos de terceros en su propio nombre es un delito. El usuario es el único responsable de responder a las autoridades competentes por cualquier daño causado y exime a PANTHER7 de cualquier responsabilidad que pueda existir.
 
* En caso de omisión, falsificación o alteración de documentos, el usuario tendrá su cuenta bloqueada y los fondos retenidos por el sitio.
 
PANTHER7 puede, a su exclusivo criterio, solicitar a los Usuarios documentos e información adicionales para mantener el Registro. Si el usuario no presenta la documentación requerida dentro de un período estipulado, el usuario puede ser suspendido o no registrado. Esto también ocurrirá si se ingresa información falsa o incompleta. En cualquier caso, PANTHER7 puede suspender o cancelar permanentemente el acceso de dicho usuario a todos los servicios y funciones del Sitio, sin perjuicio de otras medidas que están aseguradas por ley. PANTHER7 puede, a su sola discreción, rechazar cualquier solicitud de registro y / o suspender / cancelar un registro existente, especialmente en el caso de:
• Desobediencia o violación de cualquier disposición contenida en este documento;
• Incapaz de verificar, incompatibilidad o inconsistencia en los datos de identidad del usuario o falsificar la información provista;
• Práctica fraudulenta, actos maliciosos o participar en cualquier comportamiento que, a discreción de PANTHER 7, sea incompatible con los propósitos del Sitio;
Sin embargo, sabe que si su suscripción se suspende o cancela por alguna de las razones provistas en estos Términos de uso, todos sus pedidos o solicitudes que aún no se hayan realizado se cancelarán automáticamente. PANTHER7 siempre actuará por la seguridad de todos los Usuarios, incluido el Usuario suspendido o cancelado.
Su cuenta PANTHER7 es única e intransferible, por lo que solo puede registrarse por ID o CORREO ELECTRÓNICO. Además, acepta mantener sus contraseñas, como la contraseña de acceso al sitio web o la aplicación, así como otras contraseñas individuales, bajo su custodia y confidencialidad, de modo que PANTHER7 no será responsable de ningún daño. causado a usted o a un tercero por su divulgación y mal uso. Además, se compromete a notificar de manera rápida y precisa a PANTHER7 sobre cualquier uso no autorizado de su Cuenta PANTHER7, así como sobre el acceso no autorizado de terceros o el intento de acceso. PANTHER7 se reserva el derecho de bloquear su contraseña si se verifican movimientos atípicos de su cuenta PANTHER7 de acuerdo con la prevención de fraude y otros procesos de monitoreo de actividades ilegales.
PANTHER7 puede, en cualquier momento, eliminar todo o parte de la CUENTA del usuario que no esté de acuerdo con las disposiciones de estos Términos de uso, y DEFINITIVAMENTE retener los fondos / saldos que puedan estar disponibles para usted.
Los Ingresos
El cliente solo puede usar los servicios de su cuenta PANTHER7 para el "propósito del sitio". No puede utilizar este servicio por ningún otro objetivo no autorizado. Caso PANTHER7 considere que un cliente está utilizando este servicio para cualquier otro propósito que no sea el autorizado, PANTHER7 tiene el derecho de determinar la suspensión de la cuenta de ese cliente y el cliente deberá a su vez:
(a) Ser responsable de todos los daños incurridos; y
(b) Compensar PANTHER7 según sea necesario por dicho daño.
Del mismo modo, PANTHER7 se reserva el derecho de retener todos y cada uno de los valores pertenecientes a la cuenta del cliente en cuestión como resultado del uso "no autorizado" de este servicio, incluidas las actividades que no pertenecen a un propósito autorizado.
PANTHER7 puede comunicarse con cualquier agencia en cualquier momento si cree que esta o cualquier otra transacción se enmarca como sospecha de fraude o puede estar socavando cualquier ley del sistema financiero nacional o extranjero.
 
Retirada
Retiro es la denominación que se da al servicio de retiro a través de las opciones disponibles en la plataforma.
Condiciones básicas para el retiro de su cuenta PANTHER7;
todos los documentos solicitados deben estar de acuerdo con el registro y deben estar validados y aprobados por cumplimiento.
No se procesarán retiros a una cuenta de terceros, solo a cuentas del mismo titular de la cuenta PANTHER7.
Los retiros se pueden hacer haciendo clic en 'Retirar fondos' en el sitio web de PANTHER7 siempre que haya suficientes fondos en Wallet.
PANTHER7 no cobra tarifas de retiro, solo deduce el monto cobrado por los bancos al momento de la transferencia.
4.2.1. Retiro internacional
Los impuestos de envío internacional serán responsabilidad del usuario, por lo que PANTHER7 los transferirá a usted mediante débito directo a su billetera. Si existe una restricción que impide temporalmente el envío de divisas al extranjero, usted será responsable del monto de cualquier variación de cambio del envío, cuando esté autorizado.
Además, reconoce y acepta que (i) PANTHER7 puede proporcionar información a las agencias apropiadas sobre todas sus transacciones realizadas, previa solicitud.
 
Bonificación
PANTHER7 se reserva el derecho de reclamar el monto de cualquier bono, depósito, crédito o aumento de pago que se haya pagado por error, así como débito o revertir montos erróneos de su cuenta sin perjuicio de PANTHER7.


                                                        </p> 

                                                        <h2 className="mt-5 mb-0">Datos Personales del Usuario</h2>
                                                        <hr className="bg-info"/>   

                                                        <p> PANTHER7 procesa varias categorías de Datos personales del Usuario, entre las que se incluyen Datos de identificación Usuario, datos obtenidos en el proceso de diligencia debida, información de contacto, datos de transacciones de Usuarios en la Plataforma, datos de comunicaciones, datos de cookies y otras informaciones proporcionadas por el Usuario u obtenidas por parte de Terceros durante el proceso de diligencia debida.
 
 PANTHER7 procesa las siguientes categorías de Datos personales del Usuario:
 Datos del Usuario, lo que incluye, entre otros, el nombre completo del Usuario, sexo, fecha de nacimiento, lugar de nacimiento, número de documento de identidad, número de identificación fiscal, número de identificación del inversor, formulario de información sobre el documento de identidad, copia de un documento de identidad, fotografía, número de cuenta bancaria del usuario, puesto, área de trabajo e idioma preferido.
 Datos del proceso de diligencia debida, lo que incluye, entre otros, origen de los fondos, información sobre el propósito y naturaleza prevista de la relación empresarial, e información obtenida mediante actividades basadas en la evaluación del riesgo.
 Información de contacto, lo que incluye la dirección del Usuario, país de residencia, dirección de correo electrónico, número de teléfono y dirección IP.
 Datos sobre transacciones, lo que incluye los fondos invertidos del Usuario, inversiones, transacciones, pagos recibidos, desembolsos de dinero solicitados, información sobre los acuerdos de asignación celebrados, rentabilidad anual neta, divisa seleccionada, fondos disponibles y cuentas de contabilidad.
 Datos de comunicaciones, lo que incluye la comunicación del Usuario con PANTHER7 a través de la Plataforma mediante chat en directo, correo electrónico o teléfono, o mediante otras herramientas que puedan añadirse en el futuro, así como la información obtenida mediante encuestas y cuestionarios.
 Cualquier otra información que el Usuario haya proporcionado a PANTHER7 o que PANTHER7 haya recopilado por su parte mediante las fuentes que permite la legislación vigente, lo que incluye los medios e Internet.
  
 Fines del procesamiento de los datos personales
 En aquellos casos en los que el Usuario no facilite sus datos personales cuando el procesamiento de estos sea necesario para el cumplimiento del Acuerdo o cuando así lo establezca la normativa vigente, PANTHER7 no podrá ofrecer los servicios.
  
 Uso de cookies
 La Plataforma utiliza cookies para mantener y mejorar el funcionamiento de esta. Puede obtener más información sobre la forma en la que estas se utilizan en la “Política de Privacidad de Cookies”.
  
 Prestación de servicios al Usuario
 Tras la presentación de la Solicitud de registro, así como el registro en la Plataforma, los datos personales del Usuario se utilizarán para celebrar el Acuerdo, lo que incluye, entre otros pasos, la identificación del Usuario, la creación de la Cuenta virtual que va a utilizarse para recibir los Servicios y para la prestación de Servicios. Esto implica que los sub propósitos especificados a continuación y el procesamiento de datos subyacente están entrelazados, por lo que un propósito no puede llevarse a cabo sin que se lleven a cabo el resto.
  
 Identificación del usuario y celebración del Acuerdo
 Con el fin de identificar al Usuario y celebrar el Acuerdo, se emplean los Datos del Usuario, la Información de contacto, los datos sobre Diligencia debida, los datos sobre Transacciones y los datos sobre Comunicación. La identificación del Usuario se lleva a cabo tal y como se describe en la sección 4 de esta Política de privacidad.
  
 Estos datos son necesarios para verificar la identidad del Usuario, celebrar y concluir el Acuerdo, comunicarse con el Usuario en relación con el cumplimiento de este y validar la dirección de correo electrónico proporcionada por el Usuario. El tratamiento de los datos personales se basa en la celebración y cumplimiento del Acuerdo y en el cumplimiento de los legítimos intereses de PANTHER7, tales como informar al Usuario sobre la actividad en la cuenta virtual y la protección de sus derechos e intereses en caso de litigio, así como en el cumplimiento de la obligación legal de almacenar la información sobre las actividades comerciales realizadas, así como para llevar los procedimientos de diligencia debida según lo establecido en la legislación aplicable.
  
 Creación y mantenimiento de la Cuenta Virtual
 Con el fin de crear y mantener la Cuenta virtual, se emplean los Datos del Usuario, la Información de contacto, los Datos sobre transacciones y los Datos sobre comunicación. Asimismo, también se procesarán datos como la contraseña del Usuario. En cualquier caso, ni PANTHER7 ni nuestros socios colaboradores tendrán acceso a esta. Estos datos son necesarios para proporcionar opciones de acceso a la Plataforma, para comunicarse con el Usuario en caso de que sea necesario llevar a cabo cualquier consulta y para asegurar un medio por el cual el Usuario pueda recibir los Servicios. El tratamiento de los datos personales se basa en la celebración y cumplimiento del Acuerdo, así como en el cumplimiento de los legítimos intereses de PANTHER7, tales como el mantenimiento de la Cuenta virtual, el suministro de información al Usuario sobre la actividad en la Cuenta virtual y la protección de sus derechos e intereses en caso de litigio. PANTHER7 ofrece al Usuario la oportunidad de optar por no recibir determinados avisos en relación con los Servicios, como resúmenes diarios y avisos sobre depósitos. El Usuario puede elegir las notificaciones que desea recibir en la Cuenta virtual.
  
 Prestación de servicios
 Para la prestación de los Servicios según lo dispuesto en el Acuerdo, se utilizan los Datos del usuario, la Información de contacto y los Datos sobre transacciones. También pueden procesarse otros tipos de datos personales del Usuario obtenidos de este o creados durante el proceso de prestación de los Servicios cuando sea necesario. Estos datos son necesarios para garantizar los depósitos y retiradas de fondos, el envío de resúmenes diarios y avisos sobre los depósitos en efectivo, la prestación de cualquier servicio relacionado derivado del Acuerdo y la prevención del fraude y uso fraudulento de los Servicios. El tratamiento de los datos personales se basa en la celebración y cumplimiento del Acuerdo, así como en el cumplimiento de los legítimos intereses de PANTHER7, tales como el suministro de información al Usuario sobre la actividad en la Cuenta virtual y la protección de sus derechos e intereses en caso de litigio.
  
 Envío de recordatorios de verificación
 Con el fin de proporcionar Servicios y dar pasos previos a la conclusión del Acuerdo, PANTHER7 puede enviar recordatorios de registro a los Usuarios que no hayan completado este proceso. PANTHER7 podrá enviar recordatorios en los siguientes intervalos de tiempo: 24 horas, 3 días, 7 días, 30 días y 60 días tras el inicio del proceso de registro.
  
  
  
 Aplicación de las obligaciones en materia legislativa y normativa
 Con el fin de cumplir con las obligaciones legales en materia de contabilidad y de prevención del blanqueo de capitales y de la financiación del terrorismo, PANTHER7 procesa los Datos del Usuario, los Datos sobre comunicación, la Información de contacto y la información especificada por el Usuario en el cuestionario del Usuario, según lo dispuesto en las leyes y normativas internacionales.
  
 Diligencia debida
 Tal y como establecen la legislación y normativa aplicables, PANTHER7 lleva a cabo procedimientos sobre diligencia debida relativos al Usuario, lo que incluye, entre otros, la identificación del Usuario y la verificación de su documento de identidad, así como el procesamiento de los datos sobre Diligencia debida, lo que incluye el origen de los fondos de los Usuarios. Puesto que el proceso de diligencia debida y de identificación del Usuario se lleva a cabo de forma remota, PANTHER7 cuenta con obligaciones más estrictas en relación con los Usuarios en este ámbito. Para los fines de verificación, se utiliza un portal externo. La identificación del Usuario y la verificación del documento de identidad de los Usuarios se lleva a cabo mediante un portal de verificación externo, que procesa los datos de los Usuarios y de Diligencia debida. Durante este proceso, el Usuario envía al portal de verificación una foto o vídeo en los que aparece el rostro del Usuario y su documento de identidad, utilizando para ello un sistema diseñado de forma específica por el portal de verificación externo. Este procesamiento de datos es necesario para concluir y formalizar el Acuerdo, así como para cumplir las obligaciones legales de PANTHER7 en materia de lucha contra el blanqueo de capitales y la financiación del terrorismo.
  
 Envío de materiales informativos
 Al solicitar la recepción de materiales informativos, lo que incluye materiales promocionales, el Usuario acepta que los Datos del Usuario, la Información de contacto y ciertos Datos sobre transacciones (fondos invertidos y pagos recibidos) se utilicen para elaborar una comunicación comercial apropiada para el Usuario basada en los datos de este disponibles para PANTHER7, utilizando perfiles, y para segmentar al Usuario en una categoría determinada. El Usuario puede retirar su consentimiento para la recepción de materiales informativos (como boletines, ofertas de nuevos productos, etc.) en cualquier momento. Los materiales informativos incluyen todas las comunicaciones de PANTHER7 que no estén relacionadas con la prestación de Servicios, el cumplimiento del Acuerdo u otra información de carácter esencial (como, por ejemplo, un aviso de que el documento de identidad proporcionado va a caducar). PANTHER7 utiliza perfiles, es decir, medios automatizados de tratamiento de datos de carácter personal, que permiten evaluar los intereses de los Usuarios, determinar qué Servicios son los más adecuados para el Usuario, analizar la actividad del Usuario y segmentar al Usuario en una determinada categoría. PANTHER7 procesa los datos personales del Usuario hasta que este retira el consentimiento para recibir material informativo o, en algunos casos, cuando ya no es necesario conservar pruebas de que se ha recibido el consentimiento. La retirada del consentimiento para recibir materiales informativos no afecta al consentimiento otorgado para procesar la información obtenida a través de las cookies.
  
 Control de calidad del servicio
 Basándose en su legítimo interés para mejorar la calidad de los Servicios, PANTHER7, en el ámbito de la evaluación y control de la calidad de los Servicios, procesa como mínimo los Datos de comunicación al establecer contacto con el Usuario, por ejemplo, al realizar encuestas o cuestionarios, o al resolver cualquier incidencia relativa a los Servicios.
  
 Procedimiento de seguridad
 El usuario declara ser consciente de que debe tomar las medidas necesarias para realizar las operaciones de la manera más segura posible.
 El usuario debe usar el correo electrónico del servidor confiable y seguro con una contraseña segura y confiable de su propio conocimiento, evitando usar la misma contraseña para otras operaciones realizadas en Internet.
 Las contraseñas no deben divulgarse a terceros y deben protegerse contra el acceso no autorizado. PANTHER 7 no es responsable de ningún daño causado por el mal uso de las contraseñas.
 

                                                        </p>








                                                    </Col>       
                                            </Row>
                                        </div> 
                                        <CardFooter>
                            <div className="row">
                                
                            
                                <div  className="col-12">
                                <button className=" btn mt-0 btn-block btn-primario btn-info btn-panther-power" onClick={handleClose}>
                                Continuar
                                </button>
                                </div>
                                    
                         
                            </div>
                        </CardFooter>
                                        
                                    
                        

                    </div>

                        </div>

                    </div>
                        </div>
                    </Fade>
                    </Modal> 


                     {mensaje ?  
                                 
                                    <ModalMensaje
                                                                
                                                                className={classes.modal}
                                                                open={openmensaje} 
                                                                mensaje={mensaje}
                                                                disableScrollLock={true}
                                                                props={props}
                                                                closeAfterTransition
                                                                BackdropComponent={Backdrop}
                                                                BackdropProps={{
                                                                    timeout: 900,
                                                                }}
                                                                >
                                                                <Fade in={openmensaje}
                                                                timeout={154}
                                                                onClose={handleCloseMensaje}>
                                                                    <div className="container">
                                                                <div className="row">
                                                                <div className="col-12 d-flex justify-content-center">
                                                                <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}> 
                                                                {/*                     
                                                                    <CardHeader> 
                                                                        
                                                                        <h3 className="title display-4 mb-0 text-center">BIENVENIDO </h3>
                                                                        <p className="text-center">¡Estas muy cerca de finalizar el registro de tu cuenta!</p>
                                                                        
                                                                        <hr class="my-1 border-info"/> 
                                                                        
                                                                        </CardHeader> */}
                                                                    
                                                                    <CardBody>
                                                                                        <Row className="d-flex justify-content-center">
                                                                                                <Col sm="12" md="11" className=" static-height"> 
                                                                                                
                                                                                                    
                                
                                                                                                    
                                                                                                    <hr className="bg-info"/>
                                
                                                                                                <h1 className="text-center">{mensaje}</h1>
                                                                    
                                                                                                </Col>       
                                                                                        </Row>
                                                                                    </CardBody> 
                                                                                    <CardFooter>
                                                                        <div className="row">
                                                                            
                                                                       
                                                                            <div  className="col-12">
                                                                            {mensaje === "Usuario creado con éxito. Confirme haciendo clic en el enlace que se le envió en su correo electrónico." ? 

                                                                                <button className="btn mt-0 btn-block btn-primario btn-info btn-panther-power" onClick={ e =>(
                                                                                    e.preventDefault(),
                                                                                     handleCloseMensaje(),
                                                                                     Limpiar(),
                                                                                     elGuardarCargando(false),
                                                                                     elGuardarCargando(false),
                                                                                     guardarRegistroMensaje(null),
                                                                                     LimpiarMensaje(), 
                                                                                    props.history.push('/login')
                                                                                )}>
                                                                                Iniciar sesión
                                                                                </button>

                                                                                :

                                                                                <button className="btn mt-0 btn-block btn-primario btn-info btn-panther-power" onClick={ ()=>(
                                                                                    handleCloseMensaje(),
                                                                                    Limpiar(),
                                                                                    elGuardarCargando(false),
                                                                                    elGuardarCargando(false),
                                                                                    guardarRegistroMensaje(null),
                                                                                    LimpiarMensaje()
                                                                                )}>
                                                                                Aceptar
                                                                                </button>





                                                                            }






                                                                            </div>
                                                                      
                                                                        </div>
                                                                    </CardFooter>
                                                                                    
                                                                                
                                                                    
                                
                                                                </div>
                                
                                                                    </div>
                                
                                                                </div>
                                                                    </div>
                                                                </Fade>
                                                                </ModalMensaje>            
                                : null 
                                
                                }

        </Fragment>
        </Fade>

     );
}
 
export default NuevaCuenta;
