import React, { Fragment, useContext , useEffect, useState, useMemo } from 'react';

import { Doughnut } from "react-chartjs-2";

import styled from 'styled-components'


import Modal from '@material-ui/core/Modal'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';  
import PropTypes from 'prop-types';

/// fade del modal
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import NumberFormat from 'react-number-format';
import AuthContext from '../../context/autenticacion/authContext'


import { 
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Container,
    FormGroup,
    Input
  } from "reactstrap";  

  import Spinner from '../spinners/Spinner'

  // aqui solo aparece el responsive pequenho
const DivMobile = styled.div `
padding: 0rem !important;
margin: 0.2rem 0rem 2.0rem 0rem !important;
display:flex !important;
flex-direction:row;
justify-content:center;





@media(min-width:992px) {
  display:none !important;
  justify-content:center;
}



`;

// pantalla grande
const Divdesktop = styled.div `

display:none !important;






@media(min-width:992px) {
  display:block !important;
}



`; 





//// responsive
const Div = styled.div `
  padding: 0rem !important;
  margin: 0.2rem 0rem 2.0rem 0rem !important;
  display:flex !important;
  flex-direction:row;
  justify-content:center;
  
  
  
  
  
  @media(min-width:992px) {
    display:none !important;
    justify-content:center;
  }



`;

// pantalla grande
const DivModal = styled.div `

  display:none !important;
  flex-direction:row;
  
  
  
  
  
  @media(min-width:992px) {
    display:flex !important;
  }



`; 

/// aqui vamos a poner los estilos para el tap 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#00ff9d',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);





/// aqui van los estilos de los tabs
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    alignItems:'center',
    marginRight: theme.spacing(0),
    borderRadius:'30px',
    transition:'all 500ms ease',
    padding:'0px 5px 0px 5px',
     margin:'0px 4px 0px 4px',
     alignItems:'center',
  
    '&:focus': {
      opacity: 0.8,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#005eff',
      borderRadius:'30px'
      
      
    },
    '&:hover': {
      opacity: 0.6,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#000',
      borderRadius:'30px'
    },

  }
  
  ,
}))((props) => <Tab disableRipple {...props} />);
///// aqui terminan los estilos del tap





function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


///// aqui vamos a poner la grafica 




/// chart
let chartPie = {
    data4: (canvas) => {
      let ctx = canvas.getContext("2d");
      
   
      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
      let invertido=document.getElementById("pinvertido").value;
      let disponible=document.getElementById("pdisponible").value;
  
      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      
      
     
      return {
        type: 'doughnut',
        
        datasets: [
          {
            label: "Data",
            fill: true,
            backgroundColor:['#20c284', '#1720ac' ],
            borderColor: ['#00ff9d', '#1f8ef1' ],
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [parseFloat(invertido),parseFloat(disponible)],
            weight: 300,
          } 
        ] ,
  
        labels: ['Monto Invertido','Monto Disponible'],
  
  
      };
    },
    options: chart_pie_options ,
    enable: pdisponible,
    investment: pinvertido
  
  
  }; 
  
  
   
  
  
  let pdisponible = pdisponible
  let pinvertido = pinvertido
  
  /// ahora va el option del chart
  let chart_pie_options = {
    maintainAspectRatio: true,
    legend: {
      display: true
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          DoughnutPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "#00ffc33d",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ],
      xAxes: [
        {
          Percentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ]
    }
  };
  
  
  
  


const ModalSecundario = ({modalInfo, onSubmitDos, onChange, amount, opendos, handleCloseDos, handleOpenDos, mensaje, sumaporcentaje, guardarSumaPorcentaje, cargando}) => {



  
  const guardar = ( cargando ) ? <Spinner/> :  
                                          
                                          <Fragment>
                                            Comprar 
                                            </Fragment> 


  const authContext = useContext(AuthContext);

  const { 
    
    TodosOferentes, 
    TodosOfertas,
    TodosInversiones,
    TodosInversionesVendidas,

    TodosProyectosPrimario,
    TodosProyectosSecundario, 

    Movimientos,
    demo_status

   } = authContext;  


    /////////// esto funciona para el suap

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    

    // guardarSumaPorcentaje(modalInfo.precio_original + modalInfo.rendimiento) 
    
    
    guardarSumaPorcentaje(parseFloat(parseFloat(modalInfo.precio_original_2) + parseFloat(modalInfo.rendimiento_2)).toFixed(2))
  
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    }; 

    /////////////////////////////////

    const [chartstate, setChartState] = useState("data4");

  
    // vamos a extraer el context de emergencia para guardar los valores de la inversion 
    // despues 
  
    
    // vamos a hacer que el mensaje salga
    
  
    
     // configuracion del modal de material-ui
     const [ modalStyle ] =useState(getModalStyle);
     
  
     const classes = useStyles();
  
    

  



    return ( 



        <Fragment>
              <Modal 
                            open={opendos}
                            className="modal-responsive-fix-invertir"
                       
                        >
                            <div  className={`  card  Modal-inversion  invertir-fix-mobile m-0 deposito-bg`}>
                            <CardHeader className="bg-modal-invertir ">

                              <Row>
                              <Col xs="10" sm="10" md="10" lg="10" className="btn-cancel-justify-center">
                              <h3 className="title display-5 text-left my-4">Detalle de la inversion de {modalInfo.name} </h3>
                              
                                                    </Col>
                                
                              <Col xs="2" sm="2" md="2" lg="2" className="btn-cancel-justify-center">
                                                      <div className="cancel-box-btn">
                                                          <div className="cancel-btn-panther" onClick={ () => (
                                                            handleCloseDos()
                                                          )}> 
                                                              <i className="tim-icons icon-simple-remove"/>
                                                          </div>
                                                      </div>
                                                    </Col>

                              </Row>
                                
                            </CardHeader>
                                  <CardBody className="min-height"> 
                                  <Row className="mercado-style">
                                      <Col md="12">
                                          <p>Ofertas Secundarias</p>
                                      </Col>
                                  </Row>
                                  
                                 
                                        
                                                           {/* responsive start */}
                                  <Div >
                                    <div className="responsive-width-fix">
                                        <AppBar position="static" color="default" className="app-panther-bar">
                                        <StyledTabs value={value} 
                                                  onChange={handleChange} 
                                                  aria-label="styled tabs example"
                                                  className=" pr-0 pl-0 justify-content-center"
                                                  >

                                            <StyledTab  label="Datos de la Oferta" {...a11yProps(0)} />
                                            <StyledTab label="Distribucion " {...a11yProps(1)} />
                                            <StyledTab label="Saldo en la Cuenta" {...a11yProps(2)} />
                                            
                                        </StyledTabs>


                                          
                                        </AppBar>
                                        <SwipeableViews
                                          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                          index={value}
                                          onChangeIndex={handleChangeIndex}
                                         
                                          
                                        >
                                          <TabPanel value={value} index={0} dir={theme.direction} >

                                            
                                          <Row >
                                        
                                            <Col sm="12">
                                            <div className=" m-0 ">
                                                <CardHeader  className="p-0">
                                                    <h5 className="title display-4">Datos del contrato </h5>
                                                    <p className="text-success border-success border-bottom pb-2"></p>
                                                </CardHeader>
                                                <CardBody >
                                                    
                                                    <div className="acc-config-panther">

                                                            <Container >
                                                            <Row> 
                                                            <Col xs="12" sm="12" className="align-items-center d-flex justify-content-center">
                                                                <div className="col-11 my-4">
                                                 
                                                                    <img src={modalInfo.imagen} /> 
                                                                    </div> 

                                                                    </Col>


                                                                <Col xs="6" sm="6">
                                                                 <ul className="pl-3"> 
                                                                    <li>Tipo de Oferta:   <br/><strong> {modalInfo.tipo}</strong>   </li>
                                                                    <li>Monto:<br/> <strong> {modalInfo.amount_proyecto} { demo_status === "active" ? 'USDT' : ' $ '  }    </strong> </li>
                                                                    <li>Tasa de Interés: <br/><strong> {modalInfo.fee_total_contract} % </strong> </li>
                                                                    <li>Garantía: <br/><strong> {modalInfo.warranty} </strong> </li>
                                                                    <li>Tiempo Total de Contrato <br/><strong> {modalInfo.dias} d. </strong> </li>
                                                                    
                                                                    

                                                                 </ul>

                                                                </Col>   
                                                                <Col xs="6" sm="6"> 
                                                              <ul className="pl-3">
                                                              <li>Clasificación: <br/> <strong> {modalInfo.risk} </strong> </li>
                                                                    <li>Fecha de Vencimiento: <br/><strong> {modalInfo.end_date} </strong> </li>
                                                                    <li>Monto Invertido <strong> {modalInfo.amount_invertido} { demo_status === "active" ? 'USDT' : ' $ '  } </strong> </li>
                                                                    <li>Fecha fin de la venta <strong> {modalInfo.fecha_fin}  </strong> </li>  
                                                                    <li>Tiempo Restante de Contrato: <strong> {modalInfo.tiemporemanente} d. </strong> </li>
                                                                    <li>Dias restantes <strong> {modalInfo.dias_pendientes} d. </strong> </li> 

                                                              </ul>
                                                              

                                                              </Col>



                                            

                                                            </Row>
                                                            </Container>
                                                    <div className="imagen-mis-inversiones-fix">
                                                
                                                 <div className="col-12">
                                                 <h5 className="display-mis-inversiones">{modalInfo.name} </h5>
                                                 <p className="border-success border-top pl-2 mt-2 pt-2 pb-2">{modalInfo.description}</p>
                                                 </div>
                                                 </div>
                                                
                                                
                                                    </div>
                                                </CardBody> 
                                            
                                            
                                                </div>
                                            </Col>

                                          </Row>
                                          </TabPanel>

                                          <TabPanel value={value} index={1} dir={theme.direction}>

                                            <Row >
                                                <Col sm="12"> 
                                                      <div className=" m-0">
                                                      <CardHeader  className="p-0">
                                                          <h5 className="title display-4">Distribucion del Contrato</h5>
                                                          <p className="text-success border-success border-bottom pb-2"></p>
                                                      </CardHeader>
                                                      <CardBody> 
                                                              <Col md="12">

                                                                      <div className="kyc-containDer">
                                                                      <CardBody className="p-0">
                                                                      <div className="chart-area">
                                                                        <Doughnut
                                                                          data={chartPie[chartstate]}
                                                                          options={chartPie.options}
                                                                          modalInfo={modalInfo}
                                                                          
                                                                        />
                                                                      </div>
                                                                    </CardBody>
                                                                          
                                                                      </div>

                                                                  </Col> 

                                                                  
                                                    
                                                      </CardBody> 
                                                      <CardFooter className="d-flex justify-content-center distribucion-contrato-footer"> 
                                                      <div className="row d-flex acc-config-panther justify-content-center width-100">
                                                        <div className="col-6">
                                                        <ul>      
                                                                  <li className="text-center">{modalInfo.amount_invertido} { demo_status === "active" ? 'USDT' : ' $ '  } </li> 
                                                                  <li className="text-center distribucion-contrato-footer-title" > {modalInfo.percentage_investment}  %</li> 
                                                                  <li className="text-center">Monto Invertido </li> 

                                                          </ul>

                                                        </div> 
                                                        <div className="col-6">
                                                        <ul>       
                                                                  <li className="text-center">{modalInfo.disponible} { demo_status === "active" ? 'USDT' : ' $ '  } </li> 
                                                                  <li className="text-center distribucion-contrato-footer-title"> {modalInfo.percentage_enable} %</li> 
                                                                  <li className="text-center">Monto Disponible </li> 

                                                          </ul>

                                                        </div>
                                                          </div>
                                                      </CardFooter>
                                                  
                                                </div>
                                                    
                                                
                                                </Col>
                                                </Row> 

                                          </TabPanel> 
                                          <TabPanel value={value} index={2} dir={theme.direction}>

                                            <Row >
                                                <Col sm="12"> 
                                                <form onSubmit={onSubmitDos}>
                                                

                                                        <div className=" invertir-fx m-0">
                                                        <CardHeader className="p-0">
                                                            <h5 className="title display-4">Saldo en la Cuenta</h5>
                                                            <p className="text-success border-success border-bottom pb-2"></p>
                                                            
                                                        </CardHeader>
                                                        <CardBody>
                                                            
                                                            <div className="acc-config-panther">
                                                                

                                                            <ul>    
                                                            <h3 className="text-left m-0">Disponible </h3> 
                                                              <p className="text-success border-success border-bottom text-right font-disponible">{modalInfo.disponiblewallet}</p>
                                                              <h3 className="text-left m-0">Monto a Invertir</h3> 
                                                              <label  >Precio de venta</label>
                                                                <input
                                                                    
                                                                    
                                                                    
                                                                    
                                                                    value={modalInfo.precioventa}
                                                                    className="btn-block  display-4  text-center input-invertir"
                                                                    />

                                                                    <li className="display-4"> Ganancia de periodo:    </li>
                                                                    
                                                                    <li className="text-right m-0 p-1">{modalInfo.ganancia}  % </li>
                                                                    <li className="display-4">Valor futuro:    </li>
                                                                  <li className="text-right m-0 p-1" >{modalInfo.rendimientofuturo} { demo_status === "active" ? 'USDT' : ' $ '  } </li>
                                                                    
                                                            </ul>

                                                            </div>
                                                        </CardBody> 
                                                        <CardFooter className="d-flex justify-content-end">
                                                            <Button type="submit" className=" btn-invertir  btn-block" >
                                                            {guardar}
                                                            </Button>
                                                        </CardFooter>

                                                        </div> 

                                                    </form>
                                                    
                                                  </Col>
                                                </Row> 

                                          </TabPanel>
                                    
                                        </SwipeableViews>
                                      </div>      


                                  </Div>

                                  {/* responsive end */}

                                  <DivModal >
                                           
                                            <Col sm="12" md="4">
                                                
                                                <Card className="card deposito-bg">
                                                <CardHeader>
                                                    <h5 className="title display-4">Datos del contrato </h5>
                                                    <p className="text-success border-success border-bottom pb-2"></p>
                                                </CardHeader>
                                                <CardBody>
                                                    
                                                    <div className="acc-config-panther">


                                                    <ul>

                                                            <li>Tipo de Oferta:   <strong> {modalInfo.tipo}</strong>   </li>
                                                            <li>Monto: <strong> {modalInfo.amount_proyecto} { demo_status === "active" ? 'USDT' : ' $ '  } </strong> </li>
                                                            <li>Tasa de Interés: <strong> {modalInfo.fee_total_contract} % </strong> </li>
                                                            <li>Garantía: <strong> {modalInfo.warranty} </strong> </li>
                                                            <li>Clasificación: <strong> {modalInfo.risk} </strong> </li>
                                                            <li>Fecha de Vencimiento: <strong> {modalInfo.end_date} </strong> </li>
                                                            <li>Tiempo Total de Contrato <strong> {modalInfo.dias} d. </strong> </li>
                                                            <li>Tiempo Restante de Contrato: <strong> {modalInfo.tiemporemanente} d. </strong> </li>
                                                            <li>Monto Invertido <strong> {modalInfo.amount_invertido} { demo_status === "active" ? 'USDT' : ' $ '  } </strong> </li>
                                                            <li>Dias restantes de venta <strong> {modalInfo.dias_pendientes} d. </strong> </li> 
                                                            <li>Fecha fin de la venta <strong> {modalInfo.fecha_fin}  </strong> </li> 
                                                           
                                                            
                                                            
                                                        

                                                    </ul> 
                                                    <div className="row">
                                                          <div className="col-3 pt-2">
                                                              <img src={modalInfo.imagen} /> 
                                                          </div>

                                                          <div className="col-9 mb-3">
                                                          
                                                          <p className="w-100 descripcion-fix">
                                                                  <strong> Descripción: </strong> <br/>
                                                                {modalInfo.description}

                                                                </p>
                                                          </div>
                                                   </div>
                                                
                                                
                                                    </div>
                                                </CardBody> 
                                            
                                            
                                                </Card>
                                            </Col> 
                                            <Col sm="12" md="4">
                                                

                                                <Card className="card deposito-bg">
                                                <CardHeader>
                                                    <h5 className="title display-4">Distribucion del Contrato</h5>
                                                    <p className="text-success border-success border-bottom pb-2"></p>
                                                </CardHeader>
                                                <CardBody> 
                                                        <Col md="12">

                                                                <div className="kyc-containDer">
                                                                <CardBody className="p-0">
                                                                <div className="chart-area">
                                                                  <Doughnut
                                                                    data={chartPie[chartstate]}
                                                                    options={chartPie.options}
                                                                    
                                                                    enable={chartPie.enable}
                                                                    investment={chartPie.investment}
                                                                    
                                                                  />
                                                                </div>
                                                              </CardBody>
                                                                    
                                                                </div>

                                                            </Col> 

                                                            
                                               
                                                </CardBody> 
                                                <CardFooter className="d-flex justify-content-center distribucion-contrato-footer"> 
                                                <div className="row d-flex acc-config-panther justify-content-center width-100">
                                                   <div className="col-6">
                                                   <ul>          
                                                   
                                                   <li className="text-center">{modalInfo.amount_invertido} { demo_status === "active" ? 'USDT' : ' $ '  } </li> 
                                                      
                                                            <li className="text-center distribucion-contrato-footer-title"> {modalInfo.percentage_investment}  %</li> 
                                                            <li className="text-center">Monto Invertido </li> 

                                                    </ul>

                                                   </div> 
                                                   <div className="col-6">
                                                   <ul>     <li className="text-center">{modalInfo.disponible} { demo_status === "active" ? 'USDT' : ' $ '  } </li> 
                                                            <li className="text-center distribucion-contrato-footer-title"> {modalInfo.percentage_enable} %</li> 
                                                            <li className="text-center">Monto Disponible </li> 

                                                    </ul>

                                                   </div>
                                                    </div>
                                                    <input type="hidden" id="pinvertido" value={modalInfo.percentage_investment}></input>
                                                    <input type="hidden" id="pdisponible" value={modalInfo.percentage_enable}></input>
                                                </CardFooter>
                                            
                                                </Card>
                                            </Col> 

                                            <Col sm="12" md="4">

                                                  <form onSubmit={onSubmitDos}>

                                                      <Card className="card invertir-fx deposito-bg">
                                                      <CardHeader>
                                                          <h5 className="title display-4">Saldo en la Cuenta</h5>
                                                          <p className="text-success border-success border-bottom pb-2"></p>
                                                          
                                                      </CardHeader>
                                                      <CardBody>
                                                          
                                                          <div className="acc-config-panther">
                                                              

                                                          <ul>    
                                                              <h3 className="text-left m-0">Disponible wallet</h3> 
                                                              <p className="text-success border-success border-bottom text-right font-disponible">{modalInfo.disponiblewallet}</p>
                                                            
                                
                                                                    <li className="display-4">Valor inicial de la participacion:    </li>
                                                                    <li className="text-right m-0 p-1">{modalInfo.precio_original }  { demo_status === "active" ? 'USDT' : ' $ '  } </li>
                                                                    <li className="display-4">Ganancia al dia    </li>
                                                                    <li className="text-right m-0 p-1">{modalInfo.rendimiento}  { demo_status === "active" ? 'USDT' : ' $ '  } </li>
                                                                    
                                                                    <li className="display-4">Ganancia del periodo:    </li>
                                                                    <li className="text-right m-0 p-1">{modalInfo.ganancia}  % </li>
                                                                    <li className="display-4">Rendimiento futuro:    </li>
                                                                    <li className="text-right m-0 p-1" >{modalInfo.rendimientofuturo} { demo_status === "active" ? 'USDT' : ' $ '  } </li> 


                                                                      
                                                              <h3 className="text-left m-0">Valor ofertado</h3> 
                                                              <label  >Precio de venta {modalInfo.porcentaje_costo}</label>
                                                              <input
                                                                  
                                                                  value={modalInfo.precioventa}
                                                                  className="btn-block  display-4  text-center input-invertir"
                                                                  />  
                                                                
                                                            <li className="display-4 text-center ">{modalInfo.porcentaje_costo}% de  <NumberFormat value={sumaporcentaje}  displayType={'text'} thousandSeparator={true} prefix={ demo_status === "active" ? ' USDT ' : '$'  } decimalScale={2} />   </li>


                                                                    

                                                                  
                                                                  
                                                          </ul>
                                                      
                                                          </div>
                                                      </CardBody> 
                                                      <CardFooter className="d-flex justify-content-end ">
                                                          <Button type="submit" className=" btn-invertir  btn-block" color="default" data-toggle="modal" data-target="#acc-config" data-whatever="Editar">
                                                           {guardar}
                                                          </Button>
                                                      </CardFooter>
                                                  
                                                      </Card> 

                                                      </form>
                                        </Col> 
                                    </DivModal>

                                    {/* {mensaje ? ( <span className="text-center mensaje-inversion"> <br/> {mensaje}</span>) : null} */}
                                    { mensaje ? <Modal
                                                
                                                className={classes.modal}
                                                open={opendos} 
                                                mensaje={mensaje}
                                                                  
                                                onClose={handleCloseDos}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{
                                                  timeout: 900,
                                                }}
                                              >
                                                <Fade in={opendos}
                                                timeout={154}
                                                onClose={handleCloseDos}>
                                                  <div className="container">
                                                <div className="row">
                                                  <div className="col-12 d-flex justify-content-center">
                                                  <div  className={`  card ${classes.paper} Modal-inversion modal-power-up modal-power-up-profile`}>
                                                  
                                                  <CardBody>
                                                                      <Row className="d-flex justify-content-center">
                                                                              <Col sm="12" md="6"> 
                                                                                  <p className="text-center display-4 title font-light-weight-modal"> {mensaje}</p>
                                                                              </Col>       
                                                                      </Row>
                                                                    </CardBody> 
                                                                    <CardFooter>
                                                      <div className="row">
                                                          
                                                      <div  className="col-4">
                                                          </div>
                                                          <div  className="col-4">
                                                            <button className=" btn mt-0 btn-block btn-info" onClick={ () => {
                                                                                        handleCloseDos();    
                                                                                        TodosOferentes() ;
                                                                                        TodosOfertas();
                                                                                        TodosInversiones();
                                                                                        TodosInversionesVendidas();
                                                                                        TodosProyectosPrimario();
                                                                                        TodosProyectosSecundario();
                                                                                        
                                                                                          }
                                                                                          } >
                                                            Aceptar
                                                          </button>
                                                          </div>
                                                                
                                                      <div  className="col-4">
                                                          </div>
                                                      </div>
                                                  </CardFooter>
                                                                    
                                                                
                                                  

                                                </div>

                                                  </div>

                                                </div>
                                                  </div>
                                                </Fade>
                                              </Modal> : null} 

                                              


                                  </CardBody> 
                                  
                            </div>
                        </Modal>
        </Fragment>

     );
}
 
export default ModalSecundario;