import React, { useState, useContext, useEffect, Fragment } from 'react';



import Modal from '@material-ui/core/Modal'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 
import EditModal from '@material-ui/core/Modal'

/// aqui vamos a importar como el modal entra 
/// fade del modal
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade'; 

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    
    Row,
    Col
  } from "reactstrap";


/// aqui vamos a importar los modales

function getModalStyle() {
    const top = 50 ;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      
    };
  }
  
  
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'scroll',
    height:'100%',
    display:'block'
      
    },
    paper: {
      
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      
      margin: '20% 1% 10% 1%',
    },
  }));






const TarjetaFondeo = () => {


    ////    ///edit modal
 const [openedit, setOpenEdit] =useState(false);

 
 const handleOpenEdit = () => {
  setOpenEdit(true);
} 

const handleCloseEdit = () => {
  setOpenEdit(false);
  
}




    

 // aqui vamos a importar los estados del modal
 const [ modalStyle ] =useState(getModalStyle);
 const [open, setOpen] =useState(false);

 

 const handleOpen = () => {
     setOpen(true);
 } 

 const handleClose = () => {
     setOpen(false);
     guardarMensaje(null);
 }

//// aqui vamos a colocar los estados 
const classes = useStyles();


///AQUI VAMOS A COLOCAR LOS CAMBIADORES DE EVENTOS




/// aqui vamos a colocar los estados por mientras
const [mensaje, guardarMensaje] = useState({
    message:""
    })
    
    const [retiro, guardarRetiro] = useState({
    
    amount:'',
    type: '',
    
    
    });  
    
    


const onChange = e => {

    // voy a colocar guardar usuario en el state
  
    guardarRetiro({
        ...retiro,
        [e.target.name] : e.target.value
        
    }) 
    
  
    
  }
  
  
  
  
  
  
  const onSubmit = e => {
    e.preventDefault();
  
  

  
    // luego lo vamos a pasar al action
  
    // consultarApiRetiro({
    //   amount,
    //   type,
     
  
    // });
  
  
  } 
  



    return ( 
    
    <>
             <Col md="12" className="box-perfil-center-fix">
                              

                              <Card className="card">
                              <CardHeader> 
                              
                                  <h5 className="title display-4 mb-0">Tarjetas de crédito o débito</h5>
                                  <p className="text-success border-success border-bottom pb-2 pl-2"><span className="text-muted text-4"> (para recarga o fondeo)</span></p>
                                </CardHeader>
                                <CardBody>
                                  
                                  <Row>
                                    <Col sm="6" md="12" lg="4">
                                  
                                    <div className="account-card account-card-primary text-white rounded p-3 mb-4 mb-lg-0">
                                      <p className="text-4">XXXX-XXXX-XXXX-2323</p>
                                      <p className="d-flex align-items-center">
                                         <span className="account-card-expire text-uppercase d-inline-block opacity-6 mr-2">Valid<br/>
                                        </span> <span className="text-4 opacity-9">02/04</span> 
                                        
                                        <span className="bg-light text-0 text-body font-weight-500 rounded-pill d-inline-block px-2 line-height-4 opacity-8 ml-auto">Primaria</span> 
                                                            </p>
                                      <p className="d-flex align-items-center m-0">
                                         <span className="text-uppercase font-weight-500">joel mariscal</span> 
                                        
                                          
                                        </p>
                                        <div className="account-card-overlay  p-3 mb-4 mb-lg-0"> 
                                        <p className="pr-5"    onClick={ () => {
                                                    handleOpenEdit()
                                                }}>

                                    <span className="mr-1"><i className="fas fa-edit"></i></span>Editar</p> 
                                        <p><span className="mr-1"><i className="fas fa-minus-circle"></i></span>Borrar</p> 
                                        </div>
                                      
                                    </div>
                                  
                                  </Col>
                                  <Col sm="6" md="12" lg="4">
                                  
                                  <div className="add-account-card  text-white p-3 mb-4 mt-4 mb-lg-0"
                                     onClick={ () => {
      
                                            
                                        handleOpen()
                                    
                                   
                                  }}
                                  
                                  >
                                    
                                    <div className="add-tarjeta">
                                    <i class="row fas fa-plus-circle"></i>
                                     <p className="row d-flex align-items-center m-0 text-white">
                                        Agregar una nueva Tarjeta
                                      </p>
                                      
                                     
                                    </div>
                              
                                      
                                    
                                  </div>
                                
                                </Col>
                                                      </Row>
                                </CardBody> 
                                <CardFooter className="d-flex justify-content-end">
                                  
                                </CardFooter>
                          
                              </Card>
                            </Col> 


                            {/* AQUI VA EL MODAL */}

                            <Modal
                    
                    className={classes.modal}
                    open={open} 
                    
                    disableScrollLock={true}
                    
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 900,
                    }}
                    >
                    <Fade in={open}
                    timeout={154}
                    onClose={handleClose}>
                        <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                        <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>

                        <CardHeader> 
                              
                              <h5 className="title display-4 mb-0">Añadir Tarjeta</h5>
                              <hr class="my-1 border-info"/> 
                              <br/>
                            
                            </CardHeader>
                        
                        <CardBody className="tarjeta-modal-fix">
                                            <Row className="d-flex justify-content-center">
                                                    <Col sm="12" md="11" className=" static-height"> 
                        {/* aqui esta la clase */}
                                                        
                                                        <FormGroup>
                                   < div className="form-group form-control-deposito-container">
                               <label className="deposito-label-between" for="youSend">
                                 <p>Deseas enviar <span className="text-danger">*</span></p>
                                 <p>Elegir Moneda <span className="text-success"> *  </span></p>
                                 </label> 
                               
   
                               <div className="input-group">
                                 <div className="input-group-prepend "> <span className="input-deposito-moneda input-group-text">$</span> </div>
                                 <input type="text" className="deposito-form-control-props-input form-control"   
                                 placeholder="Digite el monto"
                                 id="amount"
                                 name="amount"
                                 onChange={onChange}
                                 
                                 
                                 />
                                 <div className="input-group-append input-group-append-deposito  p-0 custom-select "> 
                                 <select id="youSendCurrency" data-style="custom-select bg-transparent border-0" data-container="body" data-live-search="true" className="selectpicker form-control p-0 custom-select-deposito bg-transparent" required="" tabindex="-98">
                                         <optgroup label="Moneda" className="bg-primary">
                                             <option data-icon="currency-flag currency-flag-usd mr-1" data-subtext="USD" selected="selected" value="">USD&nbsp;</option>
                                             <option data-icon="btc" data-subtext="BTC" value="">BTC &nbsp;</option>
                                         </optgroup>
                                       </select>
                                 </div>
                               </div> 
   
                              {/* empieza otro valor , otro campo */}


                              <label className="panther-retiro-label">Nombre Destinatario <span className="text-danger mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Nombre Destinatario"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>
                                            
                              {/* TERMINA otro valor , otro campo */} 


                              
                              <label className="panther-retiro-label">Apellido Destinatario <span className="text-danger mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Apellido Destinatario"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>
                      
   
                             
                              {/* aqui empieza el select del pasaporte */}

                              <label className="panther-retiro-label">Tipo de Identificacion <span className="text-danger mt-5">*</span></label>
                              <select 
                                id="bank"
                                name="bank"
                                onChange={onChange}
                                
                                className="form-control  pointer-cursor">
                              <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                              <option value="Allbank Corp">Pasaporte</option>
                                    <option value="BAC International Bank, Inc.">Cedula/DNI</option>
                                    <option value="Balboa Bank & Trust Corp">Licencia</option>
                                    
                                    
                              
                              </select> 

                              {/* aqui termina el select del pasaporte */}

                                
                              <label className="panther-retiro-label">Identificacion <span className="text-danger mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Identificacion Destinatario"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>

                                {/* aqui termina el select del identificacion */} 



                                <label className="panther-retiro-label">Pais <span className="text-danger mt-5">*</span> </label>
                                <select className="form-control  pointer-cursor" id="pais" name="pais">
                                <option value="" className="text-center option-fix"> --- Selecciona --- </option>
                                                        <option value="144">Afganistán</option>
                                                        <option value="114">Albania</option>
                                                        <option value="18">Alemania</option>
                                                        <option value="98">Algeria</option>
                                                        <option value="145">Andorra</option>
                                                        <option value="119">Angola</option>
                                                        <option value="4">Anguilla</option>
                                                        <option value="147">Antigua y Barbuda</option>
                                                        <option value="207">Antillas Holandesas</option>
                                                        <option value="91">Arabia Saudita</option>
                                                        <option value="5">Argentina</option>
                                                        <option value="6">Armenia</option>
                                                        <option value="142">Aruba</option>
                                                        <option value="1">Australia</option>
                                                        <option value="2">Austria</option>
                                                        <option value="3">Azerbaiyán</option>
                                                        <option value="80">Bahamas</option>
                                                        <option value="127">Bahrein</option>
                                                        <option value="149">Bangladesh</option>
                                                        <option value="128">Barbados</option>
                                                        <option value="9">Bélgica</option>
                                                        <option value="8">Belice</option>
                                                        <option value="151">Benín</option>
                                                        <option value="10">Bermudas</option>
                                                        <option value="7">Bielorrusia</option>
                                                        <option value="123">Bolivia</option>
                                                        <option value="79">Bosnia y Herzegovina</option>
                                                        <option value="100">Botsuana</option>
                                                        <option value="12">Brasil</option>
                                                        <option value="155">Brunéi</option>
                                                        <option value="11">Bulgaria</option>
                                                        <option value="156">Burkina Faso</option>
                                                        <option value="157">Burundi</option>
                                                        <option value="152">Bután</option>
                                                        <option value="159">Cabo Verde</option>
                                                        <option value="158">Camboya</option>
                                                        <option value="31">Camerún</option>
                                                        <option value="32">Canadá</option>
                                                        <option value="130">Chad</option>
                                                        <option value="81">Chile</option>
                                                        <option value="35">China</option>
                                                        <option value="33">Chipre</option>
                                                        <option value="82">Colombia</option>
                                                        <option value="164">Comores</option>
                                                        <option value="112">Congo (Brazzaville)</option>
                                                        <option value="165">Congo (Kinshasa)</option>
                                                        <option value="166">Cook, Islas</option>
                                                        <option value="84">Corea del Norte</option>
                                                        <option value="69">Corea del Sur</option>
                                                        <option value="168">Costa de Marfil</option>
                                                        <option value="36">Costa Rica</option>
                                                        <option value="71">Croacia</option>
                                                        <option value="113">Cuba</option>
                                                        <option value="22">Dinamarca</option>
                                                        <option value="169">Djibouti, Yibuti</option>
                                                        <option value="103">Ecuador</option>
                                                        <option value="23">Egipto</option>
                                                        <option value="51">El Salvador</option>
                                                        <option value="93">Emiratos árabes Unidos</option>
                                                        <option value="173">Eritrea</option>
                                                        <option value="52">Eslovaquia</option>
                                                        <option value="53">Eslovenia</option>
                                                        <option value="28">España</option>
                                                        <option value="55">Estados Unidos</option>
                                                        <option value="68">Estonia</option>
                                                        <option value="121">Etiopía</option>
                                                        <option value="175">Feroe, Islas</option>
                                                        <option value="90">Filipinas</option>
                                                        <option value="63">Finlandia</option>
                                                        <option value="176">Fiyi</option>
                                                        <option value="64">Francia</option>
                                                        <option value="180">Gabón</option>
                                                        <option value="181">Gambia</option>
                                                        <option value="21">Georgia</option>
                                                        <option value="105">Ghana</option>
                                                        <option value="143">Gibraltar</option>
                                                        <option value="184">Granada</option>
                                                        <option value="20">Grecia</option>
                                                        <option value="94">Groenlandia</option>
                                                        <option value="17">Guadalupe</option>
                                                        <option value="185">Guatemala</option>
                                                        <option value="186">Guernsey</option>
                                                        <option value="187">Guinea</option>
                                                        <option value="172">Guinea Ecuatorial</option>
                                                        <option value="188">Guinea-Bissau</option>
                                                        <option value="189">Guyana</option>
                                                        <option value="16">Haiti</option>
                                                        <option value="137">Honduras</option>
                                                        <option value="73">Hong Kong</option>
                                                        <option value="14">Hungría</option>
                                                        <option value="25">India</option>
                                                        <option value="74">Indonesia</option>
                                                        <option value="140">Irak</option>
                                                        <option value="26">Irán</option>
                                                        <option value="27">Irlanda</option>
                                                        <option value="215">Isla Pitcairn</option>
                                                        <option value="83">Islandia</option>
                                                        <option value="228">Islas Salomón</option>
                                                        <option value="58">Islas Turcas y Caicos</option>
                                                        <option value="154">Islas Virgenes Británicas</option>
                                                        <option value="24">Israel</option>
                                                        <option value="29">Italia</option>
                                                        <option value="132">Jamaica</option>
                                                        <option value="70">Japón</option>
                                                        <option value="193">Jersey</option>
                                                        <option value="75">Jordania</option>
                                                        <option value="30">Kazajstán</option>
                                                        <option value="97">Kenia</option>
                                                        <option value="34">Kirguistán</option>
                                                        <option value="195">Kiribati</option>
                                                        <option value="37">Kuwait</option>
                                                        <option value="196">Laos</option>
                                                        <option value="197">Lesotho</option>
                                                        <option value="38">Letonia</option>
                                                        <option value="99">Líbano</option>
                                                        <option value="198">Liberia</option>
                                                        <option value="39">Libia</option>
                                                        <option value="126">Liechtenstein</option>
                                                        <option value="40">Lituania</option>
                                                        <option value="41">Luxemburgo</option>
                                                        <option value="85">Macedonia</option>
                                                        <option value="134">Madagascar</option>
                                                        <option value="76">Malasia</option>
                                                        <option value="125">Malawi</option>
                                                        <option value="200">Maldivas</option>
                                                        <option value="133">Malí</option>
                                                        <option value="86">Malta</option>
                                                        <option value="131">Man, Isla de</option>
                                                        <option value="104">Marruecos</option>
                                                        <option value="201">Martinica</option>
                                                        <option value="202">Mauricio</option>
                                                        <option value="108">Mauritania</option>
                                                        <option value="42">México</option>
                                                        <option value="43">Moldavia</option>
                                                        <option value="44">Mónaco</option>
                                                        <option value="139">Mongolia</option>
                                                        <option value="117">Mozambique</option>
                                                        <option value="205">Myanmar</option>
                                                        <option value="102">Namibia</option>
                                                        <option value="206">Nauru</option>
                                                        <option value="107">Nepal</option>
                                                        <option value="209">Nicaragua</option>
                                                        <option value="210">Níger</option>
                                                        <option value="115">Nigeria</option>
                                                        <option value="212">Norfolk Island</option>
                                                        <option value="46">Noruega</option>
                                                        <option value="208">Nueva Caledonia</option>
                                                        <option value="45">Nueva Zelanda</option>
                                                        <option value="213">Omán</option>
                                                        <option value="19">Países Bajos, Holanda</option>
                                                        <option value="87">Pakistán</option>
                                                        <option value="124">Panamá</option>
                                                        <option value="88">Papúa-Nueva Guinea</option>
                                                        <option value="110">Paraguay</option>
                                                        <option value="89">Perú</option>
                                                        <option value="178">Polinesia Francesa</option>
                                                        <option value="47">Polonia</option>
                                                        <option value="48">Portugal</option>
                                                        <option value="246">Puerto Rico</option>
                                                        <option value="216">Qatar</option>
                                                        <option value="13">Reino Unido</option>
                                                        <option value="65">República Checa</option>
                                                        <option value="138">República Dominicana</option>
                                                        <option value="49">Reunión</option>
                                                        <option value="217">Ruanda</option>
                                                        <option value="72">Rumanía</option>
                                                        <option value="50">Rusia</option>
                                                        <option value="242">Sáhara Occidental</option>
                                                        <option value="223">Samoa</option>
                                                        <option value="219">San Cristobal y Nevis</option>
                                                        <option value="224">San Marino</option>
                                                        <option value="221">San Pedro y Miquelón</option>
                                                        <option value="225">San Tomé y Príncipe</option>
                                                        <option value="222">San Vincente y Granadinas</option>
                                                        <option value="218">Santa Elena</option>
                                                        <option value="220">Santa Lucía</option>
                                                        <option value="135">Senegal</option>
                                                        <option value="226">Serbia y Montenegro</option>
                                                        <option value="109">Seychelles</option>
                                                        <option value="227">Sierra Leona</option>
                                                        <option value="77">Singapur</option>
                                                        <option value="106">Siria</option>
                                                        <option value="229">Somalia</option>
                                                        <option value="120">Sri Lanka</option>
                                                        <option value="141">Sudáfrica</option>
                                                        <option value="232">Sudán</option>
                                                        <option value="67">Suecia</option>
                                                        <option value="66">Suiza</option>
                                                        <option value="54">Surinam</option>
                                                        <option value="234">Swazilandia</option>
                                                        <option value="56">Tadjikistan</option>
                                                        <option value="92">Tailandia</option>
                                                        <option value="78">Taiwan</option>
                                                        <option value="101">Tanzania</option>
                                                        <option value="171">Timor Oriental</option>
                                                        <option value="136">Togo</option>
                                                        <option value="235">Tokelau</option>
                                                        <option value="236">Tonga</option>
                                                        <option value="237">Trinidad y Tobago</option>
                                                        <option value="122">Túnez</option>
                                                        <option value="57">Turkmenistan</option>
                                                        <option value="59">Turquía</option>
                                                        <option value="239">Tuvalu</option>
                                                        <option value="62">Ucrania</option>
                                                        <option value="60">Uganda</option>
                                                        <option value="111">Uruguay</option>
                                                        <option value="61">Uzbekistán</option>
                                                        <option value="240">Vanuatu</option>
                                                        <option value="95">Venezuela</option>
                                                        <option value="15">Vietnam</option>
                                                        <option value="241">Wallis y Futuna</option>
                                                        <option value="243">Yemen</option>
                                                        <option value="116">Zambia</option>
                                                        <option value="96">Zimbabwe</option>
                   </select> 

                    {/* aqui termina el select  */}  

                    <label className="panther-retiro-label">Provincia / Estado <span className="text-danger mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Estado"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>
                                            
                              {/* TERMINA otro valor , otro campo */} 
                              <label className="panther-retiro-label">Ciudad Destinatario <span className="text-danger mt-3">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Ciudad Destinatario"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>
                                            
                              {/* TERMINA otro valor , otro campo */} 
                              <label className="panther-retiro-label">Direccion Destinatario <span className="text-danger mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Direccion Destinatario"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>
                                            
                              {/* TERMINA otro valor , otro campo */} 


                              <label className="panther-retiro-label">Teléfono Destinatario <span className="text-danger mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Teléfono Destinatario"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>


                                {/* TERMINA otro valor , telefono destinatario */} 


                              <div>
                              <label className="panther-retiro-label deposito-label-between" for="youSend">
                                  <p>Total comisiones <span className="text-danger">*</span></p>
                                  <p>5.21USD <span className="text-success"> *  </span></p>
                                  </label>  
                                <hr class="my-1 border-info"/> 
                                  
                              <label className="panther-retiro-label deposito-label-between" for="youSend">
                                  <p>Total a pagar <span className="text-danger">*</span></p>
                                  <p>1,000.00 <span className="text-success"> *  </span></p>
                                  </label>  


                              </div>
                             


   
                              </div> 



                                 
                                   </FormGroup>


                                                        
                                                    </Col>       
                                            </Row>
                                        </CardBody> 
                                        <CardFooter>
                            <div className="row justify-content-center">
                                
                          
                                <div  className="col-sm-12 col-md-6 col-12">
                                <button className=" btn mt-0 btn-block btn-info btn-panther-power" onClick={handleClose}>
                                Solicitar Tarjeta
                                </button>
                                </div>
                                    
                            
                            </div>
                        </CardFooter>
                                        
                                    
                        

                    </div>

                        </div>

                    </div>
                        </div>
                    </Fade>
                    </Modal> 

                    <EditModal
                    
                    className={classes.modal}
                    open={openedit} 
                    
                    disableScrollLock={true}
                    
                    onClose={handleCloseEdit}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 900,
                    }}
                    >
                    <Fade in={openedit}
                    timeout={154}
                    onClose={handleCloseEdit}>
                        <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                        <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>

                        <CardHeader> 
                              
                              <h5 className="title display-4 mb-0">Añadir cuenta bancaria</h5>
                              <hr class="my-1 border-info"/> 
                              <br/>
                            
                            </CardHeader>
                        
                        <CardBody className="tarjeta-modal-fix">
                                            <Row className="d-flex justify-content-center">
                                                    <Col sm="12" md="11" className=" static-height"> 
                        {/* aqui esta la clase */}
                                                        
                                                        <FormGroup>
                                   < div className="form-group form-control-deposito-container">
                               <label className="deposito-label-between" for="youSend">
                                 <p>Deseas enviar <span className="text-danger">*</span></p>
                                 <p>Elegir Moneda <span className="text-success"> *  </span></p>
                                 </label> 
                               
   
                               <div className="input-group">
                                 <div className="input-group-prepend "> <span className="input-deposito-moneda input-group-text">$</span> </div>
                                 <input type="text" className="deposito-form-control-props-input form-control"   
                                 placeholder="Digite el monto"
                                 id="amount"
                                 name="amount"
                                 onChange={onChange}
                                 
                                 
                                 />
                                 <div className="input-group-append input-group-append-deposito  p-0 custom-select "> 
                                 <select id="youSendCurrency" data-style="custom-select bg-transparent border-0" data-container="body" data-live-search="true" className="selectpicker form-control p-0 custom-select-deposito bg-transparent" required="" tabindex="-98">
                                         <optgroup label="Moneda" className="bg-primary">
                                             <option data-icon="currency-flag currency-flag-usd mr-1" data-subtext="USD" selected="selected" value="">USD&nbsp;</option>
                                             <option data-icon="btc" data-subtext="BTC" value="">BTC &nbsp;</option>
                                         </optgroup>
                                       </select>
                                 </div>
                               </div> 
   
                              {/* empieza otro valor , otro campo */}


                              <label className="panther-retiro-label">Nombre Destinatario <span className="text-danger mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Nombre Destinatario"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>
                                            
                              {/* TERMINA otro valor , otro campo */} 


                              
                              <label className="panther-retiro-label">Apellido Destinatario <span className="text-danger mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Apellido Destinatario"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>
                      
   
                             
                              {/* aqui empieza el select del pasaporte */}

                              <label className="panther-retiro-label">Tipo de Identificacion <span className="text-danger mt-5">*</span></label>
                              <select 
                                id="bank"
                                name="bank"
                                onChange={onChange}
                                
                                className="form-control  pointer-cursor">
                              <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                              <option value="Allbank Corp">Pasaporte</option>
                                    <option value="BAC International Bank, Inc.">Cedula/DNI</option>
                                    <option value="Balboa Bank & Trust Corp">Licencia</option>
                                    
                                    
                              
                              </select> 

                              {/* aqui termina el select del pasaporte */}

                                
                              <label className="panther-retiro-label">Identificacion <span className="text-danger mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Identificacion Destinatario"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>

                    {/* aqui termina el select  */}  

                    <label className="panther-retiro-label">Provincia / Estado <span className="text-danger mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Estado"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>
                                            
                              {/* TERMINA otro valor , otro campo */} 
                              <label className="panther-retiro-label">Ciudad Destinatario <span className="text-danger mt-3">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Ciudad Destinatario"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>
                                            
                              {/* TERMINA otro valor , otro campo */} 
                              <label className="panther-retiro-label">Direccion Destinatario <span className="text-danger mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Direccion Destinatario"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>
                                            
                              {/* TERMINA otro valor , otro campo */} 


                              <label className="panther-retiro-label">Teléfono Destinatario <span className="text-danger mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Teléfono Destinatario"
                                  id="note"
                                  name="note"
                                  onChange={onChange}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>


                                {/* TERMINA otro valor , telefono destinatario */} 


                              <div>
                              <label className="panther-retiro-label deposito-label-between" for="youSend">
                                  <p>Total comisiones <span className="text-danger">*</span></p>
                                  <p>5.21USD <span className="text-success"> *  </span></p>
                                  </label>  
                                <hr class="my-1 border-info"/> 
                                  
                              <label className="panther-retiro-label deposito-label-between" for="youSend">
                                  <p>Total a pagar <span className="text-danger">*</span></p>
                                  <p>1,000.00 <span className="text-success"> *  </span></p>
                                  </label>  


                              </div>
                             


   
                              </div> 



                                 
                                   </FormGroup>


                                                        
                                                    </Col>       
                                            </Row>
                                        </CardBody> 
                                        <CardFooter>
                            <div className="row justify-content-center">
                                
                          
                                <div  className="col-sm-12 col-md-6 col-12">
                                <button className=" btn mt-0 btn-block btn-info btn-panther-power" onClick={handleClose}>
                                Añadir cuenta bancaria
                                </button>
                                </div>
                                    
                            
                            </div>
                        </CardFooter>
                                        
                                    
                        

                    </div>

                        </div>

                    </div>
                        </div>
                    </Fade>
                    </EditModal>

    </> 
    
    
    );
}
 
export default TarjetaFondeo;