import clienteAxios from "config/axios";
import React, { useEffect, useState } from "react";
import { FC_targetas } from "./functions";
// import ReactInputMask from "react-input-mask";
// import Spinner from '../../components/spinners/Spinner.css'
import { Redirect } from "react-router-dom";
import LogoWhite from '../../assets/scss/black-dashboard-react/panther/logo-white.svg'; 
import Spinner from "components/spinners/Spinner";
import LineSpinner from "components/spinners/LineSpinner";
import InputMask from "./InputMask";
import NumberFormat from "react-number-format";

function Transfer() {
  const [tarjetas, setTargetas] = useState([]);

  const [showMessage, setShowMessage] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [dataTransfer, setdataTransfer] = useState({
    card_id: "",
    amount: "",
    notes: "",
  });
  useEffect(() => {
    const handleTarjetas = async () => {
      try {
        const respuesta = await clienteAxios.post("/api/card/list");
  
        setTargetas(respuesta.data.data);
        
      } catch (error) {
        setTargetas([]);
      }
    };

    handleTarjetas();
  }, []);

  const onChange = (e) => {
      setdataTransfer({
        ...dataTransfer,
        [e.target.name]: e.target.value,
      });
    
  };

  function replaceCommasWithDots(inputString) {
    const removeDollar = inputString.replace(/\$/g, "");
    const changeToDot = removeDollar.replace(/,/g, ".");
    return changeToDot;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const card_id =
      !dataTransfer.card_id || dataTransfer.card_id === "Seleccione"
        ? ""
        : dataTransfer.card_id;
    const amount = !dataTransfer.amount ? "" : dataTransfer.amount;
    const notes = !dataTransfer.notes ? "" : dataTransfer.notes;

    const data = {
      card_id: card_id,
      amount: amount,
      notes: notes,
    };

    const emptyFields = Object.keys(data).filter((key) => !data[key]);

    if (emptyFields.length > 0) {
      const errorMessage = `Los siguientes campos son requeridos: ${emptyFields.join(
        " | "
      )}`;
      setErrorMessage(errorMessage);
      setShowMessage(true);
      return;
    } else {
      const data = {
        card_id: card_id,
        amount: replaceCommasWithDots(amount),
        notes: notes,
      };

      setLoading(!false);
      try {
        const registerTransfer = async () => {
          await clienteAxios.post("/api/wallet/transfer", data).then((res) => {
            if (res.data.success) {
              setSuccessMessage('Transferencia realizada con éxito');
              setShowSuccessMessage(!false);
              setLoading(false);
            }
          });
        };
        registerTransfer();
      } catch (error) {
        setSuccessMessage(error);
        setShowSuccessMessage(!false);
        setLoading(false);
      }
    }
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  return (
    <div className="ultimos-grid-movimientos animation-cuentas cursor-pointer position-relative">
      {showMessage && (
        <div className={"Mensaje  "}>
          <div className="mensaje-box">
          <img className="logo-size-2" src={LogoWhite} alt="" />
            <h2>{errorMessage}</h2>
            <div className="btn-box-panther-mensaje">
              <button
                className="btn-panther"
                onClick={() => setShowMessage(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

      {showSuccessMessage && (
        <div className="Mensaje">
          <div className="mensaje-box">
            <img className="logo-size-2" src={LogoWhite} alt="" />
            <h1>{successMessage}</h1>
            <div className="btn-box-panther-mensaje">
              <button
                className="btn-panther"
                onClick={() => handleCloseSuccessMessage(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      ) }

      <form className="" onSubmit={handleSubmit}>
        <div className="tittle-box-transfer">
          <h2 className="">
          Ahora puedes transferir desde tu Wallet a tus tarjetas.
          </h2>
          <h3 className="">Seleccione una Tarjeta, establezca el valor y deje una referencia de su retiro.
</h3>
          <h3>
            www<strong>.panther7.</strong>com
          </h3>
        </div>
        <div className="box-data-complete-transfer">
          <div className="input-size-jimf">
            <label>Tarjeta</label>
            <select
              style={{padding: '10px !important;'}}
              name="card_id"
              defaultValue={dataTransfer.card_id}
              className="control-vb"
              onChange={onChange}
              // required
            >
              <option name="card_id" defaultValue="empty">
                Seleccione
              </option>
              {tarjetas.map((tarjeta) => {
                return (
                  <option name="card_id" key={tarjeta.id} value={tarjeta.id}>
                    {!tarjeta.number
                      ? "0000********0000"
                      : FC_targetas.formatCardNumber(tarjeta.number)}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="input-size-jimf">
            <label>Valor</label>
            <input
              type="number"
              id="amount"
              name="amount"
              onChange={onChange}
              defaultValue={dataTransfer.amount}
              placeholder="$0.00"
              max="3000"
              required
            />
            { dataTransfer.amount !== '' ?
                  <span>Usted está reportando un depósito de <NumberFormat value={dataTransfer.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2} className="color-bust-amount" /></span>
                  :
                  <span>
                          Escriba el monto de su depósito en el campo superior 
                  </span>
            }
          </div>

          <div className="input-size-jimf">
            <label>Notas</label>
            <input
              type="text"
              id="notes"
              name="notes"
              onChange={onChange}
              defaultValue={""}
              placeholder={"Notas"}
              // required
            />
          </div>
        </div>

        <div className="box-panther7 d-flex align-items-center text-center">
          <button type="submit" className={"submit-jimf py-auto"}>
            {loading ? <LineSpinner /> : 'Transferir'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Transfer;
