import clienteAxios from "config/axios";
import React, { useEffect, useState } from "react";
// import ReactInputMask from "react-input-mask";
import '../../assets/scss/black-dashboard-react/panther/_tarjetas.scss'
import LineSpinner from "components/spinners/LineSpinner";
import LogoWhite from '../../assets/scss/black-dashboard-react/panther/logo-white.svg'; 

function FormEdit({
    data, onUpdate, onDataChange, 
    errorMessage, editState, setEditState,
    loading

  }) {
  const [editedData, setEditedData] = useState(data);

  const [handleCountries, setHandleCountries] = useState([
    {
      id: "",
      iso: "",
      name: "",
    },
  ]);

  useEffect(() => {
    const consultarTargetas = async () => {
      try {
        const countries = await clienteAxios.post("/api/paises/list");
        setHandleCountries(countries.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    // console.log(usuario);
    consultarTargetas();
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(editedData);
  };


  return (
    <div className="position-relative ">
      <form
        className="animation-targetas-up "
        onSubmit={handleSubmit}
      >
        <div className="tittle-box">
          <h2>Para agregar una tarjeta, debes actualizar tus datos.</h2>
          <h3>
            www<strong>.panther7.</strong>com
          </h3>

          {(errorMessage && editState) &&
            <div className={"Mensaje  "}>
              <div className="mensaje-box">
              <img src={LogoWhite} alt="logopanther"/>
              <h2>{errorMessage}</h2>
                <div className="btn-box-panther-mensaje">
                  <button className="btn-panther" onClick={setEditState}>Ok</button>
                </div>
              </div>
            </div>
          }

          {/* {(registerSuccess && successState) &&
            <div className={"Mensaje  "}>
              <div className="mensaje-box">
              <h2>{registerSuccess}</h2>
                <div className="btn-box-panther-mensaje">
                  <button className="btn-panther" onClick={setSuccessState}>Ok</button>
                </div>
              </div>
            </div>
          } */}


        </div>

        <div className="box-data-complete">
          <div className="input-size-jimf">
            <label>Primero Nombre </label>

            <input
              type="text"
              id="first_name"
              name="first_name"
              onChange={onDataChange}
              defaultValue={editedData.name.split(" ")[0]}
              placeholder={"Primero nombre"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Segundo Nombre</label>

            <input
              type="text"
              id="last_name"
              name="last_name"
              onChange={onDataChange}
              defaultValue={editedData.name.split(" ")[1]}
              placeholder={"Segundo nombre"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Tipo de documentación</label>
            <select
              name="document_type"
              defaultValue={editedData.document_type}
              onChange={onDataChange}
              className="control-vb"
            >
              <option name="document_type" defaultValue="cedula" selected>
                Cédula/DNI
              </option>
              <option name="document_type" defaultValue="Pasaporte">
                Pasaporte
              </option>
            </select>
            {/* {errors.document_type && <p className="text-danger fs-3 text-red-600 position-absolute bottom-0 end-0">{errors.document_type}</p>} */}
          </div>

          <div className="input-size-jimf">
            <label>Cedula</label>

            <input
              type="text"
              id="cedula"
              name="cedula"
              onChange={onDataChange}
              defaultValue={editedData.identity}
              placeholder={"Sin agregar"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Email</label>

            <input
              type="text"
              id="email"
              name="email"
              onChange={onDataChange}
              defaultValue={editedData.email}
              placeholder={"Email"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Telephone</label>

            <input
              type="text"
              id="phone"
              name="phone"
              onChange={onDataChange}
              defaultValue={editedData.telephone}
              placeholder={"Telephone"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Fecha de Nacimiento </label>

            <input
              type="date"
              id="birthDate"
              name="birthDate"
              onChange={onDataChange}
              defaultValue={editedData.birthday_date}
              placeholder={"Fecha de Nacimiento"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Sexo </label>

            <select
              name="genero"
              defaultValue={editedData.genero}
              onChange={onDataChange}
              className="control-vb"
              selected
            >
              <option name="genero" value={''}>
                Seleccione
              </option>
              <option name="genero" value={'Masculino'}>
                Masculino
              </option>
              <option name="genero" value={'Feminino'}>
                Feminino
              </option>
            </select>
            {/* {errors.document_type && <p className="text-danger fs-3 text-red-600 position-absolute bottom-0 end-0">{errors.document_type}</p>} */}
          </div>

          <div className="input-size-jimf">
            <label>Profesion </label>

            <input
              type="text"
              id="profesion"
              name="profesion"
              onChange={onDataChange}
              defaultValue={editedData.comercial_name}
              placeholder={"Profesion"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Estado Civil </label>
            <input
              type="text"
              id="estadocivil"
              name="estadocivil"
              onChange={onDataChange}
              defaultValue={editedData.estadocivil}
              placeholder={"Estado civil"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Nacionalidad</label>
            <select
              id="nacionalidad"
              name="nacionalidad"
              defaultValue={editedData.address_country}
              onChange={onDataChange}
              className="control-vb"
            >
              <option name="nacionalidad" value="" selected>
                Seleccione
              </option>
              {handleCountries.map((country) => {
                return (
                  <option
                    name="nacionalidad"
                    key={country.id}
                    value={country.id}
                  >
                    {country.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="input-size-jimf">
            <label>País </label>

            <select
              name="country_id"
              defaultValue={editedData.address_country}
              onChange={onDataChange}
              className="control-vb"
            >
              <option name="country_id" value="" selected>
                {/* {editedData.address_country} */}
                Seleccione
              </option>
              {handleCountries.map((country) => {
                return (
                  <option
                    name="country_id"
                    key={country.id}
                    value={country.id}
                  >
                    {country.iso}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="input-size-jimf">
            <label>Zona residencial </label>

            <input
              type="text"
              id="address_zone"
              name="address_zone"
              onChange={onDataChange}
              defaultValue=""
              placeholder={"Zona residencial"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Provincia</label>

            <input
              type="text"
              id="state"
              name="state"
              onChange={onDataChange}
              defaultValue={editedData.address_zone}
              placeholder={"Zona residencial"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Ciudad </label>

            <input
              type="text"
              id="city"
              name="city"
              onChange={onDataChange}
              defaultValue={editedData.address_city}
              placeholder={"Ciudad"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Dirección </label>

            <input
              type="text"
              id="address_street"
              name="address_street"
              onChange={onDataChange}
              defaultValue={editedData.address_street}
              placeholder={"Dirección"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Dirección complementaria </label>

            <input
              type="text"
              id="address_number"
              name="address_number"
              onChange={onDataChange}
              defaultValue={editedData.address_number}
              placeholder={"Dirección Complementaria"}
            />
          </div>

          <div className="input-size-jimf">
            <label>Código Postal </label>

            <input
              type="text"
              id="zipCode"
              name="zipCode"
              onChange={onDataChange}
              defaultValue={editedData.address_zipcode}
              placeholder={"Código Postal"}
            />
          </div>
        </div>

        <div className="box-panther7 d-flex align-items-center text-center">
          <button 
            type="submit" 
            className={"submit-jimf py-2"}
          >
            {loading ? <LineSpinner /> : 'Confirmar'}
          </button>
        </div>
      </form>

      <div className={"ultradepositowhite"}>
        <div className={"ultradeposito-son-white"}></div>
      </div>
    </div>
  );
}

export default FormEdit;
